import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { GATEWAY_SCOPES, GATEWAY_SCOPES_TYPES } from './gateway-vars';
import { createLogger } from './helpers/logger';
import { DEFAULT_PKCE_CONFIG_VARS } from './pkce-config-vars';

// initialise default variables
const {
  OPENID_CALLBACK, AUTHORITY, SCOPES, OPENID_POST_LOGOUT_REDIRECT_URI,
} = DEFAULT_PKCE_CONFIG_VARS.AUTH;
const { CACHE_LOCATION, STORE_AUTH_STATE_IN_COOKIE } = DEFAULT_PKCE_CONFIG_VARS.CACHE;
const { IS_GATEWAY_SCOPE_REQUIRED, GATEWAY_SCOPE_TYPE } = DEFAULT_PKCE_CONFIG_VARS.GATEWAY;
const { LOG_LEVEL } = DEFAULT_PKCE_CONFIG_VARS.LOGGING;

const defaultConfig = {
  clientId: '',
  openIdCallback: OPENID_CALLBACK,
  authority: AUTHORITY,
  scopes: SCOPES,
  openidPostLogoutRedirectUri: OPENID_POST_LOGOUT_REDIRECT_URI,
  isGatewayScopeRequired: IS_GATEWAY_SCOPE_REQUIRED,
  gatewayScopeType: null,
  cacheLocation: CACHE_LOCATION,
  storeAuthStateInCookie: STORE_AUTH_STATE_IN_COOKIE,
  logLevel: LOG_LEVEL,
};
class AuthProvider extends PublicClientApplication {
  constructor(providedConfig) {
    const pkceConfig = {
      ...defaultConfig,
      ...providedConfig,
    };
    const {
      clientId, authority, scopes, isGatewayScopeRequired, openIdCallback, openidPostLogoutRedirectUri, cacheLocation, storeAuthStateInCookie, logLevel,
    } = pkceConfig;

    let { gatewayScopeType } = pkceConfig;
    // check if gateway scope was required but gateway scope type wasn't provided, set gateway scope type
    if (isGatewayScopeRequired && (gatewayScopeType === null)) {
      gatewayScopeType = GATEWAY_SCOPE_TYPE;
    }
    const redirectUri = window.location.origin + openIdCallback;
    const postLogoutRedirectUri = window.location.origin + openidPostLogoutRedirectUri;

    const config = {
      auth: {
        clientId,
        redirectUri,
        authority,
        postLogoutRedirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation,
        storeAuthStateInCookie,
      },
    };

    super(config);

    this.login = this.login.bind(this);
    this.handlePromiseRedirectCallback = this.handlePromiseRedirectCallback.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.getIdTokenClaims = this.getIdTokenClaims.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getGatewayAccessToken = this.getGatewayAccessToken.bind(this);
    this.signout = this.signout.bind(this);
    this.constructSilentRequest = this.constructSilentRequest.bind(this);
    this.authParameters = {
      scopes,
    };
    this.fetchGatewayAccessTokenWhileLogin = this.fetchGatewayAccessTokenWhileLogin.bind(this);
    this.isGatewayScopeRequired = isGatewayScopeRequired;
    this.gatewayScopeType = gatewayScopeType;
    this.customLogger = createLogger(logLevel);
  }

  async login() {
    await this.handleRedirectPromise();
    const accounts = this.getAllAccounts();
    if (accounts.length === 0) {
      this.loginRedirect();
    } else {
      console.error("spa_auth",'Unable to Login');
    }
  }

  constructSilentRequest(authParameters) {
    if (!(this.getAllAccounts()).length > 0) {
      this.customLogger.debug({ file_name: 'auth.js', function: 'constructSilentRequest' }, 'unable to get Account Information from cache');
      return null;
    }
    const currentAccountName = this.getAllAccounts()[0].username;
    const currentAccount = this.getAccountByUsername(currentAccountName);
    const silentRequest = {
      scopes: authParameters.scopes,
      account: currentAccount,
      forceRefresh: false,
    };
    return silentRequest;
  }

  async fetchAccessTokenSilent(authParameters) {
    try {
      this.customLogger.info({ file_name: 'auth.js', function: 'fetchAccessTokenSilent' }, 'fetching access token silently');
      const silentRequest = this.constructSilentRequest(authParameters);
      if (!silentRequest) {
        return null;
      }
      const response = await this.acquireTokenSilent(silentRequest);
      return response;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        this.customLogger.debug({ file_name: 'auth.js', function: 'fetchAccessTokenSilent' }, 'interaction required');
        return this.acquireTokenRedirect(authParameters);
      }
      throw error;
    }
  }

  signout() {
    this.customLogger.debug({ file_name: 'auth.js', function: 'logout' }, 'logging out');
    this.logout();
  }

  getIdTokenClaims() {
    if (this.getAllAccounts()) {
      this.customLogger.debug({ file_name: 'auth.js', function: 'getIdTokenClaims' }, 'found id token');
      return this.getAllAccounts()[0];
    }
    throw new Error(
      'User isn\'t logged in',
    );
  }

  async getAccessToken() {
    const { authParameters } = this;
    this.customLogger.debug({ file_name: 'auth.js', function: 'getAccessToken' }, `authParameters${JSON.stringify(authParameters)}`);
    const scopes = authParameters;
    this.customLogger.debug({ file_name: 'auth.js', function: 'getAccessToken' }, `fetching access token with the following scopes: ${JSON.stringify(scopes)}`);
    const response = await this.fetchAccessTokenSilent(scopes);
    if (!response) {
      this.customLogger.debug({ file_name: 'auth.js', function: 'getAccessToken' }, 'unable to fetch Access Token, initiating User Login');
      return this.login();
    }
    this.customLogger.debug({ file_name: 'auth.js', function: 'getAccessToken' }, `fetched access token response: ${JSON.stringify(response)}`);
    if (!response.accessToken) {
      throw new Error(
        'Can\'t construct an AccessTokenResponse.',
      );
    }
    const token = {
      accessToken: response.accessToken,
      expiresOn: response.expiresOn,
      scopes: response.scopes,
      idTokenClaims: response.idTokenClaims,
    };
    return token;
  }

  async getGatewayAccessToken() {
    if (this.isGatewayScopeRequired) {
      this.customLogger.debug({ file_name: 'auth.js', function: 'getAccessToken' }, 'fetching gateway access token');
      let scopes;
      if (this.gatewayScopeType === GATEWAY_SCOPES_TYPES.PRODUCTION) {
        scopes = { scopes: [GATEWAY_SCOPES.PRODUCTION_GATEWAY_SCOPE] };
      } else if (this.gatewayScopeType === GATEWAY_SCOPES_TYPES.NON_PRODUCTION) {
        scopes = { scopes: [GATEWAY_SCOPES.NON_PRODUCTION_GATEWAY_SCOPE] };
      }
      const response = await this.fetchAccessTokenSilent(scopes);
      if (!response) {
        this.customLogger.debug({ file_name: 'auth.js', function: 'getGatewayAccessToken' }, 'unable to fetch Gateway Access Token, initiating User Login');
        return this.login();
      }
      this.customLogger.debug({ file_name: 'auth.js', function: 'getAccessToken' }, `fetched access token response: ${JSON.stringify(response)}`);
      if (!response.accessToken) {
        throw new Error(
          'Can\'t construct an AccessTokenResponse.',
        );
      }
      const token = {
        accessToken: response.accessToken,
        expiresOn: response.expiresOn,
        scopes: response.scopes,
        idTokenClaims: response.idTokenClaims,
      };
      return token;
    }
    throw new Error(
      "You didn't provide consent for Gateway scopes while logging in. ",
    );
  }

  async handlePromiseRedirectCallback(processLogin) {
    const tokenResponse = await this.handleRedirectPromise();
    if (tokenResponse) {
      this.customLogger.debug({ file_name: 'auth.js', function: 'handlePromiseRedirectCallback' }, `fetched access token response after interaction at ${new Date().toString()}`);
      // process login callback - perform actions like chaning the app state to authenticated
      processLogin();
      // fetches Gateway token scopes if required
      if (this.isGatewayScopeRequired === true) {
        if (this.gatewayScopeType === GATEWAY_SCOPES_TYPES.PRODUCTION) {
          this.fetchGatewayAccessTokenWhileLogin({ scopes: [GATEWAY_SCOPES.PRODUCTION_GATEWAY_SCOPE] });
        } else if (this.gatewayScopeType === GATEWAY_SCOPES_TYPES.NON_PRODUCTION) {
          this.fetchGatewayAccessTokenWhileLogin({ scopes: [GATEWAY_SCOPES.NON_PRODUCTION_GATEWAY_SCOPE] });
        } else {
          throw new Error(
            'Gateway scope env variable was not provided or invalid Gateway scope env was provided.',
          );
        }
      }
    } else if (tokenResponse === null) {
      // tokenResponse was null, attempt sign in or enter unauthenticated state for app
      this.customLogger.debug({ file_name: 'auth.js', function: 'handlePromiseRedirectCallback' }, 'tokenResponse was null, attempt sign in');
      this.login();
    } else {
      this.customLogger.debug({ file_name: 'auth.js', function: 'handlePromiseRedirectCallback' }, `tokenResponse was not null but did not have any tokens:${JSON.stringify(tokenResponse)}`);
    }
  }

  async fetchGatewayAccessTokenWhileLogin(authParameters) {
    try {
      const silentRequest = this.constructSilentRequest(authParameters);
      await this.acquireTokenSilent(silentRequest);
    } catch (error) {
      const { errorCode } = error;
      if (errorCode === 'no_tokens_found') {
        this.acquireTokenRedirect(authParameters);
      } else {
        throw error;
      }
    }
  }

  async isLoggedIn() {
    try {
      const { authParameters } = this;
      const silentRequest = this.constructSilentRequest(authParameters);
      await this.acquireTokenSilent(silentRequest);
      return true;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        this.customLogger.debug({ file_name: 'auth.js', function: 'isLoggedIn' }, 'signing out since refresh token has expired.');
        return this.signout();
      }
      return false;
    }
  }
}

export default AuthProvider;
