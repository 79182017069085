import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DatatTableEx from '../DataTableEx';
import API from '../../../api/apis';
import DTAButton from '../Button/DTAButton';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
import UserContext from '../../../context/UserContext';
import Alert from 'react-bootstrap/Alert';
import swal from 'sweetalert';
import CountModalEx from './CountModalEx';
import BootstrapModal from '../../common/modal/BootstrapModal';
import LoaderEx from '../loader/LoaderEx';
class CountEx extends Component {
    static contextType = UserContext;
    state = {
        CountList: [],
        is_Coordinator: false,
        access: false,
        filterSource: [],
        showLoader: false,
        showModal: false,
        editMode: null
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            excludeFilter: true,
            hidden: true,
            csvExport: false,
            formatter: (cell, row, rowIndex) => {
                return <p onClick={() => this.handleModal(true, "edit", row, rowIndex)}></p>
            }
        }, {
            dataField: 'name',
            text: 'Count',
            sort: true,
        },
        {
            dataField: 'actions',
            text: "Actions",
            csvExport: false,
            excludeFilter: true,
            headerClasses: "width-100",
            formatter: (cell, row, rowIndex) => {
                return <>
                    {this.state.is_Coordinator && <DTAButton onClick={() => this.handleCountDelete(row)} icon="DELETE" text="DELETE"></DTAButton>}
                </>
            }

        }
    ]
    attachColumEvent = () => {
        this.columns.map((column, index) => {
            column.events = this.columnEvent;
        });
    }
    columnEvent = {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            if (column.dataField != 'actions') {
                this.handleModal(true, "edit", row, rowIndex)
            }

        }
    }
    componentDidMount() {
        this.attachColumEvent()
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator, access: _isCoordinator });
        this.getCountList();
    }
    handleCountDelete = async (row) => {
        let payload={
            is_deleted: true,
            id: row.id,
            updated_by: this.context.userSystemId,
            name: row.name
        }
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        // const res =await API.delete(`/seed/count/delete/${row.id}`);
                        const res = await API.post(`/seed/count/update`, payload);
                        if (res.data.success) {
                            swal("Deleted Successfully", {
                                icon: "success",
                            });
                            this.getCountList();
                        }
                        else {
                            this.setState({ errorModal: true });
                        }
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    //this.getCountList();
                }
            });

    }
    getCountList = async () => {
        this.setState({
            showLoader: true,
        })
        const res = await API.get(`seed/count/data`);
        if (res && res.data&&res.data.success) {
            this.setState({
                CountList: res.data.data || [],
                showLoader: false
            })
        } else {
            this.setState({ errorModal: true })
        }

    }
    handleCountSave = async (state) => {
        state.updated_by = null;
        state.created_by = this.context.userSystemId;
        state.active = true;
        this.setState({ showLoader: true });
        try {
            const res = await API.post("/seed/count/addcount", state);
            if (res && res.data && res.data.success) {
                this.setState({
                    success_body: "Created successfully!",
                    successModal: true,
                    hasChanged: false,
                    showModal: false,
                    editMode: null,
                });
                this.getCountList();
            } else {
                this.setState({ errorModal: true });
            }

        } catch (err) {
            this.setState({ errorModal: true });
        } finally {
            this.setState({ showLoader: false });
        }
    }
    handleCountUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        this.setState({ showLoader: true });
        try {
            const res = await API.post("/seed/count/update", state);
            if (res && res.data && res.data.success) {
                this.setState({
                    success_body: "Updated successfully!",
                    successModal: true,
                    hasChanged: false,
                    showModal: false,
                    editMode: null,
                });
                this.getCountList();
            } else {
                this.setState({ errorModal: true });
            }

        } catch (err) {
            this.setState({ errorModal: true });
        } finally {
            this.setState({ showLoader: false });
        }
    }
    handleModal = async (flag, editMode, row) => {
        this.setState({ showModal: flag, editMode, rowId: row.id });

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getCountList();
    }
    render() {
        let { successModal, success_body, errorModal, showLoader } = this.state;
        return <><div>
            {showLoader && <LoaderEx />}
            <DatatTableEx
                autoFilter={true}
                filterChange={() => { }}
                csvName="Count_details"
                data={this.state.CountList}
                title={<Alert.Heading>
                    <span><i class="bi bi-people" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                    Counts</Alert.Heading>} columns={this.columns}
                pageIcons={this.state.is_Coordinator && <DTAButton onClick={() => this.handleModal(true, null, {})} icon="ADD" text="Add Count"></DTAButton>}>
            </DatatTableEx>
            {this.state.showModal &&
                <CountModalEx
                    rowId={this.state.rowId}
                    handleCountSave={this.handleCountSave}
                    handleCountUpdate={this.handleCountUpdate}
                    errorModal={errorModal}
                    editMode={this.state.editMode}
                    showModal={this.state.showModal}
                    handleModal={this.handleModal} />}</div>
            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            } </>

    }
}
export default withRouter(CountEx);