import React, { Component } from 'react';
import axios from 'axios';
import ImpactScale from './detailSubComponents/section-3/ImpactScale';
import API from "../../api/apis";
import { Accordion, Card, Row, Col, Nav, Button, FormControl, Form, Dropdown, Modal, InputGroup, DropdownButton, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import InitialSubmission from './detailSubComponents/InitialSubmission';
import Concept from './detailSubComponents/section-1/Concept';
import Protocol from './detailSubComponents/section-1/Protocol';
import Proposal from './detailSubComponents/section-1/Proposal';
import FilesInternal from './detailSubComponents/section-2/FilesInternal';
import History from './detailSubComponents/section-2/History';
import Publication from './detailSubComponents/section-1/Publication';
import Reviews from './detailSubComponents/section-1/Reviews';
import DrugOrder from './detailSubComponents/section-1/DrugOrder/DrugOrder';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../common/sticky-nav/StickyNav';
import { NavLink } from 'react-router-dom';
import Enrollments from './detailSubComponents/section-3/Enrollments';
import Reforecast from './detailSubComponents/section-3/Reforecast';
import Expenses from './detailSubComponents/section-3/Expenses';
import Contacts from './detailSubComponents/section-3/Contacts';
import Sae from './detailSubComponents/section-3/Sae';
import PrimaryInvestigator from './detailSubComponents/PrimaryInvestigator';
import Finaces from './detailSubComponents/Finaces';
import Enrollment from './detailSubComponents/Enrollment';
import CoordinatorConfirmations from './detailSubComponents/CoordinatorConfirmations';
import Attestation from './detailSubComponents/Attestation';
import SubmissionHelp from './detailSubComponents/SubmissionHelp';
import Organization from './detailSubComponents/Organization';
import Milestones from './detailSubComponents/Milestones';
import Loader from '../common/loader/Loader';
import DrugDeliverySite from './detailSubComponents/section-3/DrugDeliverySite';
import BootstrapModal from '../common/modal/BootstrapModal';
import UpdateMilestones from './detailSubComponents/UpdateMilestones';
import StudyCompounds from './detailSubComponents/section-3/StudyCompounds';
import UserContext from '../../context/UserContext';
import FilesInvShare from './detailSubComponents/section-2/FilesInvShare';
import { dateChange } from '../../utility/helperFunctions';
import SearchResults from '../SearchResults'
import swal from 'sweetalert';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DetailSearch from './DetailSearch';
import ButtonNew from "../common/Button/index.js";

export default class Detail extends Component {
    static contextType = UserContext;
    state = {
        searchedData: [],
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        defaultTab: "Concept",
        search: "",
        is_search: false,
        showLoader: false,
        submissionDetails: {},
        error: false,
        successModal: false,
        errorModal: false,
        errorMessage: "",
        errorInfo:"",
        showHideModal: false,
        showSearchResults: false,
        openAccrodion:{},
        refreshAuditRequired: false,
    }
    child = React.createRef();
    childInitial = React.createRef();
    childPrimary = React.createRef();
    //childEnroll = React.createRef();
    childFinance = React.createRef();
    childImpact =React.createRef();
    childOrganization = React.createRef();
    childAttest = React.createRef();
    childSubmissionHelp = React.createRef();
    childCoordinator = React.createRef();
    titleRef = React.createRef();
    componentDidMount() {
        
        let defaultTab = localStorage.getItem("tab");
        localStorage.removeItem("tab");

        //console.log("locaaaall", defaultTab)
        console.clear();
       // console.log("Stage-1")

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            defaultTab: defaultTab ? defaultTab : "Concept",
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        })
        let submissionId=this.props.submissionId||this.props.match.params.submissionId;
        this.getSubmissionDetail(submissionId);
    }
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            })
            let submissionId=this.props.submissionId||this.props.match.params.submissionId;
            this.getSubmissionDetail(submissionId);
        }
    }

    getSubmissionDetail = async (submissionId) => {

        //console.log("Stage-2")

        this.setState({
            submissionDetails: {}
        })
        try {
            let submissionDetails = {};
            if (this.state.selectedRole.includes("read_only_oncology")) {
                submissionDetails = await API.post(`submission/getbyarea/${submissionId}`, {
                    area: ["Oncology"]
                });
            }
            else if (this.state.selectedRole.includes("read_only_diabetes")) {
                submissionDetails = await API.post(`submission/getbyarea/${submissionId}`, {
                    area: ["Diabetes & Obesity"]
                });
            }
            else if (this.state.selectedRole.includes("read_only_biomeds")) {
                submissionDetails = await API.post(`submission/getbyarea/${submissionId}`, {
                    area: [
                        "None",
                        "Immunology",
                        "Neurodegenerative Diseases",
                        "Headache & Pain"
                    ]
                });
            }
            else{
                const res = await API.get(`submission/getbyid/${submissionId}`);
                submissionDetails = (res && res.data && res.data.success) ? res.data : false;
            }
            this.setState({
                submissionDetails: (submissionDetails && submissionDetails.data) ? submissionDetails.data : "No access"
            }, () => { 
                // console.log(this.state.submissionDetails)
             })
        } catch (e) {
            console.error("getSubmissionDetail:Details.js",e);
            this.setState({
                error: true
            })
        }
    }
    onInputChange = (e) => {
        //console.log(e.target.value)
        this.setState({ search: e.target.value });
    }
    searchSubmission = () => {

        window.location = window.location.origin + `/submissions/${this.state.search}`

    }
    validateFinancesData(obj) {
        let validationFlag = true
        let costField = ["start_up", "cost_per_patient", "max_trial_expense", "final_payment", "total_grant"];
        Object.keys(obj).forEach((key) => {
            if (costField.includes(key) && (!new RegExp(/^\d*\.?\d*$/).test(obj[key]))) {
                validationFlag = false;
                return;
            }
        });
        return validationFlag;
    }
    submitForm = async () => {
      
        let key=new Date().getTime();
        
        const milestonesData = await this.child.current.milestonesData();
        const submissionHelpData = await this.childSubmissionHelp.current.submissionHelpData();
        //const financesData = await this.childFinance.current.financesData();
        const coordinatorData = await this.childCoordinator.current.coordinatorData();
        const attestationData = await this.childAttest.current.attestationData();
        const organizationData = await this.childOrganization.current.organizationData();
        const initialSubmissionData = await this.childInitial.current.initialSubmissionData();
        //const enrollData = await this.childEnroll.current.enrollData();
        const primaryInvestigatorData = await this.childPrimary.current.primaryInvestigatorData();
        const impactScaleData=await this.childImpact.current.impactScaleData()

        // if (!this.validateFinancesData(financesData)) {
        //     this.setState({
        //         errorModal: true,
        //         errorMessage: 'Please see the error highlighted.',
        //         openAccrodion:{defaultActiveKey:"0",key:key}
        //     })
        //     return;
        // }
       
        const obj = {
//...enrollData,
            //...financesData,
            ...milestonesData,
           "impact_scale_details":[...impactScaleData],
            ...submissionHelpData,
            ...initialSubmissionData,
            ...primaryInvestigatorData,
            ...coordinatorData,
            ...organizationData,
            ...attestationData,
            id: this.state.submissionDetails.id,
            last_modified_by: this.context.userSystemId
        };
        
        if (obj.investigator_primary_institution) {
            if (obj.concept)
                obj.concept.primary_institution = obj.investigator_primary_institution;
            else
                obj.concept = {
                    id: this.state.submissionDetails.concepts[0].id,
                    primary_institution: obj.investigator_primary_institution
                };

            delete obj.investigator_primary_institution
        }
        if (!obj.error) {
            try {
                
                const res = await API.patch("submission/update", obj);
                this.setState({
                    successModal: true,
                    hasChanged: false,
                    showLoader: false,
                    submissionDetails: {},
                    modalBody: "Updated Successfully!",
                openAccrodion:{}
                });
                let submissionId=this.props.submissionId||this.props.match.params.submissionId;
                this.getSubmissionDetail(submissionId);

            } catch (err) {
                this.setState({
                    errorModal: true,
                    errorMessage: 'An error occured! Please try again later!',
                    openAccrodion:{defaultActiveKey:"0",key:key}
                });
            }
        } else{
            //console.log(obj);
            let errorFieldList = [];
            let errorInfo=await this.childInitial.current.getErrorMessage();
            let errorInvestigator = await this.childPrimary.current.getErrorMessage();
            errorFieldList = [...errorInfo, ...errorInvestigator];
            this.setState({
                errorModal: true,
                // errorInfo:errorInfo,
                errorInfo:errorFieldList,
                errorMessage: 'Please Enter/Select value of required field first!',
                openAccrodion:{defaultActiveKey:"0"}
            }); 
        }

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    handleUpdateMilestonesModalShowHide = (flag) => {
        this.setState({ showHideModal: flag });
    }
    handleSearchModal = () => {
        this.setState({
            showSearchResults: !this.state.showSearchResults
        },()=>{
            if(this.state.showSearchResults === false){
                localStorage.removeItem("filter_searchresults");
            }
        })
    }
    
   
    searchTopResults = async () => {
        this.setState({
            loadingList: true,
            searchedData: []
        })
        try {
            const res = await API.post(`views/search/top_five/total_count`, {
                value: this.state.search,

            })
            //console.log(res.data)
            if (res && res.data && res.data.top_five) {
                this.setState({
                    searchedData: res.data.top_five,
                    loadingList: false,
                    countList: res.data.count
                })
            }
            else if (res && (res.data.length === 0)) {
                this.setState({
                    searchedData: [],
                    loadingList: false
                })
            }
        }
        catch (error) {
            console.error("searchTopResults:Details.js",error);
            this.setState({
                searchedData: [],
                loadingList: false
            })
        }
    }
    handleUpdateMilestones = async (updatedMilestones, submissionDetails) => {
        let obj = {
            id: updatedMilestones.submissionId
        }
        Object.keys(updatedMilestones).map(item => {
           
            if (item.indexOf("_check") > -1 && updatedMilestones[item]) {
                let itemProp = item.replace("_check", '');
                obj[itemProp] = dateChange(updatedMilestones[itemProp]);
            }
        })
        try {
            const res = await API.patch("submission/update", obj);
            let submissionId=this.props.submissionId||this.props.match.params.submissionId;
            this.setState({
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                modalBody: "Record has been saved.",
                submissionDetails: {}
            }, () => this.getSubmissionDetail(submissionId));
        } catch (err) {
            this.setState({ errorModal: true, errorMessage: "An error occured! Please try again later!" });
        }
    }
    handleDeleteSubmission = async (id) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/submission/delete/${id}`, { system_id: this.context.userSystemId });
                        this.setState({
                            success_body: "Deleted successfully!",
                            modalBody: true,
                            hasChanged: false
                        });
                        window.location = window.location.origin + "/submissions"
                    } catch (err) {

                        this.setState({ errorModal: true, errorMessage: 'An error occured! Please try again later!' });
                    }
                }
            });
    }
    handleBackClick = () => { this.titleRef.current.scrollIntoView({behavior: 'smooth'}) }
    handleStartClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth',block:'end' })}
    searchHide =() => {
        this.setState({
            is_search: !this.state.is_search
        })
    }
    renderErrorMessage=()=>{
        return <div>
                            {this.state.errorMessage}
                            <ul>{this.state.errorInfo.map((item,index)=>{
                                return <li key={index}>{item}</li>
                            })}
                               
                            </ul>
                        </div>
    }
    refreshAuditData=(flag)=>{
        this.setState({refreshAuditRequired: flag})
    }
    render() {
        const { submissionDetails, showHideModal, modalBody, showLoader, search, defaultTab,errorModal ,openAccrodion, refreshAuditRequired} = this.state;
        let defaultEvent = defaultTab ? defaultTab : "Concept";
         
        //  if(errorModal && !openAccrodion.defaultActiveKey){
           
        //      let key=new Date().getTime();
        //      openAccrodion={defaultActiveKey:"0",key:key};
        //   // <Accrodion defaultActiveKey="0"/>
        //  }console.log(openAccrodion.defaultActiveKey)
				
        return (
            <>
                {
                    (submissionDetails == "No access") &&
                    <div className="shadow p-3 mb-5 bg-white rounded mx-auto mt-4" style={{ width: "80vW", height: "70vH" }}>
                        <i className="bi bi-lock" style={{ fontSize: "20vH", color: "#cb3128", margin: ".1rem .5rem", padding: ".1rem" }}></i>
                        <h2>ACCESS DENIED</h2><br></br>
                        <h4>You are not allowed to access this page.<br></br>Please contact your
                            Business Admin.</h4>
                    </div>
                }
                {(submissionDetails !== "No access") && (!submissionDetails.id || showLoader) ?
                    <Loader />
                    : <>
                        {(submissionDetails !== "No access") &&  <div className="row">
                    <div className="col-md-12">
                        <div className='d-flex align-items-center m-3' style={{paddingLeft:'15px',paddingRight:'15px',height:'50px'}}>
                            <div className='flex-grow-1 font-weight-bold'>
                                <span>Details</span>
                            </div>
                            {/* <div style={{ width: 'auto' }} className="d-flex justify-content-between align-items-center">
                                       
                               <DetailSearch
                               showLoader={this.state.loadingList}
                               onSelection={(type,data)=>{                             
                                   switch (type) {
                                       case "CLEAR":this.setState({ searchedData: [] });break;
                                       case "ITEM": window.location = window.location.origin + `/submissions/${data.id}`; break;
                                       case "ALL":
                                           this.setState({ searchedData: [] }, this.handleSearchModal()); break;
                                   }                                
                               }}
                               onSearch={e=>{                                
                                this.setState({search:e},()=>{
                                    this.searchTopResults();
                                });
                               }} data={this.state.searchedData||[]}></DetailSearch>
                            

                            </div> */}
                            {/* {this.state.access.edit && <div className='d-flex align-items-center justify-content-end '>
                            </div> */}
                            {/* {this.state.access.edit && <div className='d-flex align-items-center justify-content-end '>

                                <Button onClick={this.submitForm} variant="success" className="d-flex align-items-center "><span class="material-icons">
                                    save
                                </span>SAVE</Button>
                            </div>} */}
                            {this.state.access.edit && <ButtonNew style="success h-md"  className='d-flex align-items-center   ml-1 ' onClick={this.submitForm}>
                            <span class="material-icons">
                                    save
                                </span>save
                            </ButtonNew>  }
                            {this.state.access.view && <ButtonNew style="accent h-md"  className='d-flex align-items-center   ml-1 ' onClick={() => this.handleUpdateMilestonesModalShowHide(true)}>
                            <span class="material-icons">
                                    update
                                </span>Update Milestones
                            </ButtonNew>  }
                            {/* {this.state.access.edit && <div style={{ marginLeft: '5px' }} className='d-flex align-items-center justify-content-end '>
                                <Button onClick={() => this.handleUpdateMilestonesModalShowHide(true)} variant="primary" className="d-flex align-items-center "><span class="material-icons">
                                    update
                                </span>Update Milestones</Button>
                            </div>} */}
                            {/* {this.state.access.delete && <div style={{ marginLeft: '5px' }} className='d-flex align-items-center justify-content-end '>
                                <Button onClick={() => this.handleDeleteSubmission(submissionDetails.id)} variant="danger" className="d-flex align-items-center "><span class="material-icons">
                                    delete
                                </span>DELETE</Button>
                            </div>} */}
                            {this.state.access.delete && <ButtonNew style="danger h-md"  className='d-flex align-items-center   ml-1 ' onClick={() => this.handleDeleteSubmission(submissionDetails.id)}>
                            <span class="material-icons">
                                    delete
                                </span>delete
                            </ButtonNew>  }

                        </div>
                    </div>
                </div>
    //                         <Navbar expand="sm" className="nav-secondary">
                               
                              
            
           
    //                             <Navbar.Brand  style={{ fontSize: '1.5rem',"box-shadow":"none" }}href="#home">{"Details".padStart(4, '0')} </Navbar.Brand>
                               
    //                             <Navbar.Collapse id="nav-secondary" className="justify-content-end">
    //                                 <Nav>
                                    
    //                                 <Form className="d-flex ml-5" >
    //                                     <InputGroup >
    //                                         <Dropdown autoClose="true" style={{ display: "flex" }}>
    //                                             <div>
    //                                         <FormControl
    //                                                     type="text"
    //                                                     placeholder="Search"
    //                                                     className="mt-2"
    //                                                     aria-label="Search"
                                                       
    //                                                     onChange={this.onInputChange}
    //                                                    // onKeyDown={this.searchTopResults}

    //                                                 />

    //                                                 {(this.state.searchedData.length > 0) && <Dropdown.Menu className="dropdown-menu " style={{ margin: "0px", width: "88%" }}>

    //                                                     {this.state.searchedData.map((ele) => (<Dropdown.Item onClick={() => {
    //                                                         window.location = window.location.origin + `/submissions/${ele.id}`
    //                                                     }} href="#"><div>{"Submission - " + ele.id}</div><div style={{ fontSize: "small", color: "grey" }}>{`${ele["concept.investigatorname"]}${ele.trial_alias ? " | " + ele.trial_alias : ""}`}</div></Dropdown.Item>))}

    //                                                     <Dropdown.Divider />
    //                                                     <Dropdown.Item href="#" onClick={this.handleSearchModal}>Show All Results</Dropdown.Item>
    //                                                     {(this.state.loadingList && (this.state.searchedData.length === 0)) && <Dropdown.Item href="#">No Results Found</Dropdown.Item>}
    //                                                 </Dropdown.Menu>}
    //                                                 {((!this.state.loadingList) && (this.state.searchedData.length === 0)) && <Dropdown.Menu className="dropdown-menu " style={{ margin: "0px", width: "100%" }}>

    //                                                     <Dropdown.Item href="#">No Results Found</Dropdown.Item>
    //                                                 </Dropdown.Menu>}

    //                                             </div>
    //                                             <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{"Search terms: Submission Id, Investigator first and last name, trial alias, concept type, proposal and protocol title, compound, Email ID for investigator. MSL email and Investigator ID."}</Tooltip>}>
    //                                                 <i class="bi ml-2 mt-1 bi-info-circle-fill text-primary"></i>
    //                                             </OverlayTrigger>

    //                                             <Dropdown.Toggle id="dropdown-autoclose-true" disabled={this.state.search === ""} className="dropdowntoggle">
    //                                             {(this.state.loadingList) && <Button className="btn btn-primary" type="button" disabled>
    //                                                     <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    //                                                 </Button>}
    //                                                 {(!this.state.loadingList) &&
    //                                                     <Button className="btn btn-primary" type="button" disabled={this.state.search === ""} onClick={this.searchTopResults}>
    //                                                         <i className="bi bi-search"  style={{ fontSize: "1.2rem", color: "white" }}></i>
    //                                                     </Button>
    // }
    //                                                 <span>

    //                                                 </span>
    //                                             </Dropdown.Toggle>
    //                                         </Dropdown>
    //                                     </InputGroup>
    //                                 </Form>
    //                                 </Nav>

    //                             </Navbar.Collapse>
    //                         </Navbar>


                        }
                        {(submissionDetails !== "No access"&& submissionDetails && submissionDetails.id) && <div className='container ' style={{}}>
                            
                        <Accordion {...openAccrodion} ref={this.titleRef} className="border rounded">
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}} >

                                        <Accordion.Toggle  as={Card.Header} eventKey="0"  >
                                            Initial Submission
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <InitialSubmission submissionDetails={submissionDetails} ref={this.childInitial} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>
                        
                                        <Accordion.Toggle as={Card.Header} eventKey="1" >
                                            Primary Investigator
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <PrimaryInvestigator submissionDetails={submissionDetails} ref={this.childPrimary} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={this.handleBackClick}>
                                            Organization
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                                <Organization submissionDetails={submissionDetails} ref={this.childOrganization} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={this.handleBackClick}>
                                            Milestones
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>
                                                <Milestones submissionDetails={submissionDetails} ref={this.child} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card style={{ "margin-bottom": " 0! important", "box-shadow": "none" }}>

<Accordion.Toggle as={Card.Header} eventKey="4" onClick={this.handleBackClick}>
    Impact Scale
</Accordion.Toggle>

<Accordion.Collapse eventKey="4">
    <Card.Body>
        <ImpactScale submissionDetails={submissionDetails} ref={this.childImpact}/>
    </Card.Body>
</Accordion.Collapse>
</Card> 

                                    {/* <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="4" onClick={this.handleBackClick}>
                                            Finances
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body>
                                                <Finaces submissionDetails={submissionDetails} ref={this.childFinance} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                    {/* <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="5" onClick={this.handleBackClick}>
                                            Enrollment
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body>
                                                <Enrollment submissionDetails={submissionDetails} ref={this.childEnroll} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="6" onClick={this.handleBackClick}>
                                            Coordinator Confirmations
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body>
                                                <CoordinatorConfirmations submissionDetails={submissionDetails} ref={this.childCoordinator} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="7" onClick={this.handleBackClick}>
                                            Attestation
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="7">
                                            <Card.Body>
                                                <Attestation submissionDetails={submissionDetails} ref={this.childAttest} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>
                                    <Accordion.Toggle as={Card.Header} eventKey="9" onClick={this.handleStartClick}>
                                            Submission Help
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="9">
                                            <Card.Body>
                                                <SubmissionHelp submissionDetails={submissionDetails} ref={this.childSubmissionHelp} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                        </Card>
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="10" onClick={this. handleStartClick}>
                                            Files (Internal)
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="10">
                                            <Card.Body>
                                                <FilesInternal submissionDetails={submissionDetails} refreshAuditData={this.refreshAuditData} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="11" onClick={this. handleStartClick}>
                                            Files (Inv Share)
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="11" >
                                            <Card.Body>
                                                <FilesInvShare submissionDetails={submissionDetails} refreshAuditData={this.refreshAuditData} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="12" onClick={this. handleStartClick}>
                                            File History
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="12" >
                                            <Card.Body>
                                                <History submissionDetails={submissionDetails} refreshAuditRequired={refreshAuditRequired} refreshAuditData={this.refreshAuditData} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="13" onClick={this. handleStartClick}>
                                            Enrollment
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="13" >
                                            <Card.Body>
                                            <Enrollments submissionDetails={submissionDetails} getSubmissionDetail={this.getSubmissionDetail} />
                                                <hr />
                                                <Reforecast submissionDetails={submissionDetails} getSubmissionDetail={this.getSubmissionDetail} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                                        <Accordion.Toggle as={Card.Header} eventKey="14" onClick={this. handleStartClick}>
                                            SAE
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="14" >
                                            <Card.Body>
                                                <Sae submissionDetails={submissionDetails} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

<Accordion.Toggle as={Card.Header} eventKey="15" onClick={this. handleStartClick}>
    Expenses
</Accordion.Toggle>

<Accordion.Collapse eventKey="15" >
    <Card.Body>
    <Expenses submissionDetails={submissionDetails} getSubmissionDetail={this.getSubmissionDetail} />
    </Card.Body>
</Accordion.Collapse>
</Card> */}
<Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

<Accordion.Toggle as={Card.Header} eventKey="16" onClick={this. handleStartClick}>
        Drug Delivery Sites
</Accordion.Toggle>

<Accordion.Collapse eventKey="16" >
    <Card.Body>
        <DrugDeliverySite submissionDetails={submissionDetails} />
    </Card.Body>
</Accordion.Collapse>
</Card>
<Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

<Accordion.Toggle as={Card.Header} eventKey="17" onClick={this. handleStartClick}>
        Contacts
</Accordion.Toggle>

<Accordion.Collapse eventKey="17" >
    <Card.Body>
        <Contacts submissionDetails={submissionDetails} />
    </Card.Body>
</Accordion.Collapse>
</Card>
<Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

<Accordion.Toggle as={Card.Header} eventKey="18" onClick={this. handleStartClick}>
        Study Compound
</Accordion.Toggle>

<Accordion.Collapse eventKey="18" >
    <Card.Body>
    <StudyCompounds submissionDetails={submissionDetails} getSubmissionDetail={this.getSubmissionDetail}></StudyCompounds>
    </Card.Body>
</Accordion.Collapse>
</Card> 
                                
                    
                 
                                    
                                   
                                </Accordion>
                        </div>
                        }
                    </>
                }
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body={modalBody}
                        changeShowState={this.changeShowState}
                        buttonText="Ok"
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body={this.renderErrorMessage()}
                        changeShowState={this.changeShowState}
                    />
                }
                {
                    showHideModal &&
                    <UpdateMilestones
                        showHideModal={showHideModal}
                        handleUpdateMilestonesModalShowHide={this.handleUpdateMilestonesModalShowHide}
                        handleUpdateMilestones={this.handleUpdateMilestones}
                        submissionDetails={submissionDetails}
                    />
                }
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered show={this.state.showSearchResults} onHide={this.handleSearchModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Search Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showSearchResults ?
                            <SearchResults searchText={this.state.search}></SearchResults> :
                            null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleSearchModal}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}
