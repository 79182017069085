export const SERVICE_DOMAIN="";
export const SERVICE_ENV={
  "DEV":"https://6gah8x5fyc.execute-api.us-east-2.amazonaws.com/dev/dashboard",
  "QA":"https://f1b8y0lfd2.execute-api.us-east-2.amazonaws.com/qa/dashboard",
  "PROD":"https://lrmbpely45.execute-api.us-east-2.amazonaws.com/prod/dashboard"
}
// export const SERVICE_ENV={
//   "DEV":"http://localhost:3001",
//   "QA":"http://localhost:3001",
//   "PROD":"http://localhost:3001"
// }
export const FILTER_TYPES={
  'assign_to':1,
  'therapeutic_area':2,
  'stage':3,
  'request_type':4,
  'trial_alias':5,
  'submission_id':6,
  'compound':7,
  'country':8
}
export const REDIRECT_DOMAIN={
  DEV:'/toreview/details'
}
export const forms={
  'DASHBOARD0':{form:'DashboardF',title:'Portfolio Progression'},
  'DASHBOARD1':{form:'DashboardA',title:'Number of active studies in the IIR Portal'},
  'DASHBOARD2':{form:'DashboardB',title:'Study enrolled vs estimated patient numbers plus patients currently on treatment '},
  'DASHBOARD3':{form:'DashboardC',title:'Submission Stage Summary'},
  'DASHBOARD4':{form:'DashboardD',title:'Submission Stage - Timeline'},
  'DASHBOARD5':{form:'DashboardE',title:'Country Specific Submissions'},
  'DASHBOARD6':{form:'DashboardG',title:'Active trial status quarter by quarter'},
  'DASHBOARD7':{form:'DashboardH',title:'Feasibilities – Reviews'},
  'DASHBOARD8':{form:'DashboardI',title:'Cycle time from Concept to Active Trial'}

}
export const colors=['#ff1744','#af52bf','#4CAF50','#D81B60','#E1BEE7','#4DD0E1','#1DE9B6','#FFA726','#FF3D00']