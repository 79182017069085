import React, { Component } from "react";

import Topnav from "./common/topnav/Topnav";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import API from '../api/apis';
import axios from 'axios';

import { AuthProvider } from "../spa_auth";

import Submission from './submission/Submission';
// import Investigators from './investigation/Investigators';
import InvestigatorsEx from './investigation/InvestigatorsEx';
import ToReview from './ToReview';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from "react-router-dom";
import InvestigatorDetail from "./investigation/InvestigatorDetail";
import { Container } from "react-bootstrap";
import SubmissionDetail from "./submission/SubmissionDetail";
import NewConcept from "./NewConcept";
import NewPublication from "./NewPublication";
import Footer from "./Footer";
import NewReview from "./review/NewReview";
import ReviewDropdown from "./review/ReviewDropdown";
import ErrorBoundary from "./common/error-boundary/ErrorBoundary";
import ConceptDetail from "./submission/detailSubComponents/ConceptDetail";
import Loader from "./common/loader/Loader";
import NewProposal from "./NewProposal";
import ProposalDetail from "./submission/detailSubComponents/ProposalDetail";
import NewProtocol from "./NewProtocol";
import NewDiagnostic from "./NewDiagnostic";
import NewTranslational from "./NewTranslational";
import NewDrugDeliverySite from "./NewDrugDeliverySite";
import DiagnosticDetails from "./submission/detailSubComponents/DiagnosticDetails";
import ProtocolDetail from "./submission/detailSubComponents/ProtocolDetail";
import DrugDeliverySiteDetail from "./submission/detailSubComponents/DrugDeliverySiteDetail"
import EnrollmentDetails from "./submission/detailSubComponents/EnrollmentDetails"
import ReforecastDetails from "./submission/detailSubComponents/ReforecastDetails"
import ExpensesDetails from "./submission/detailSubComponents/ExpensesDetails";
import ContactDetails from "./submission/detailSubComponents/ContactDetails";
import UserContext from "../context/UserContext";
import DelegeeDetail from "./submission/detailSubComponents/DelegeeDetail";
import NewDelegee from "./NewDelegee";
import StudyCompoundDetails from "./submission/detailSubComponents/StudyCompoundDetails";
import CountryDetail from "./common/existing/CountryDetail"; 
import SaeDetail from "./submission/detailSubComponents/SaeDetail";
import DrugOrderDetails from "./submission/detailSubComponents/section-1/DrugOrder/DrugOrderDetails";
import Country from "./common/existing/Country";
import CountryEx from "./common/existing/CountryEx";
import Compound from "./common/existing/Compound";
import DrugOptionsDetail from "./submission/detailSubComponents/DrugOptionsDetail";
import CompoundDetail from "./common/existing/CompoundDetail";
import StateDetail from "./common/existing/StateDetail";
import CompoundExistDetail from "./common/existing/CompoundExistDetail";
import PublicationDetail from "./PublicationDetail";
import AmendmentDetail from "./AmendmentDetail";
import {USER_ROLE} from "../mapping"
import ReviewConceptDetail from "./submission/detailSubComponents/ReviewConceptDetail";
import ReviewProtocolDetail from "./submission/detailSubComponents/ReviewProtocolDetail";
import ReviewProposalDetail from "./submission/detailSubComponents/ReviewProposalDetail";
import ReviewAmendmentDetail from "./submission/detailSubComponents/ReviewAmendmentDetail";
import ReviewPublicationDetail from "./submission/detailSubComponents/ReviewPublicationDetail";

import DashboardFile from "./common/DashboardFile";
import Reports from "./Reports";
import Presentation from "./common/listDropdown/Presentation";
import Count from "./common/listDropdown/Count";
import CountEx from "./common/listDropdown/CountEx"
import RecycleBin from "./common/listDropdown/RecycleBin";
import RoleAudit from "./common/listDropdown/RoleAudit";
import Strength from "./common/listDropdown/Strength";
import StrengthNew from "./common/listDropdown/StrengthNew";
import PresentationDetail from "./common/listDropdown/PresentationDetail";
import CountDetail from "./common/listDropdown/CountDetail";
import StrengthDetail from "./common/listDropdown/StrengthDetail";
// import DelegeeList from "./common/listDropdown/DelegeeList";
import DelegeeListEx from "./common/listDropdown/DelegeeListEx";
import DelegeeListDetail from "./common/listDropdown/DelegeeListDetail";
import ReviewerDetails from "./submission/detailSubComponents/section-1/Reviewer/ReviewerDetails";
import ApprovalHistoryDetail from "./submission/detailSubComponents/section-2/ApprovalHistoryDetail";
import TranslationDetail from "./submission/detailSubComponents/TranslationDetail";
import ToReviewDetails from "./ToReviewDetails";
import ReviewForm from "./ToReviewDetails/Review/ReviewForm";
import Accounts from "./Accounts";
import { MAINTENANCE_MODE_DESC } from "../consts"
import Button from "./common/Button";
import SessionExpiry from "./SessionExpiry";
import moment from "moment";
import CompoundEx from "./common/existing/CompoundEx";
import Dashboard from "./Dashboard";
class App extends Component {

  state = {
    localpath: "/",

    isLoading: true,
    restrictUser: false,
    prevLocal:"",
    userInfo: {
      userRolesList: null,
      userFirstName: null,
      userLastName: null,
      userEmailId: null,
      userSystemId: null,
      rolesList: null ,
      selectedRole : null,
    },
    defaultRole: null,
    maintenanceModeRedirectFlag: false,
  }
  sessionValidatorTimer=null;
  scheduleSessionValidator=()=>{
    this.clearScheduler();
    this.sessionValidatorTimer=setInterval(this.sessionValidator,30000);
  }
  clearScheduler=()=>{
    if(this.sessionValidatorTimer){
      clearInterval(this.sessionValidatorTimer);
     
    }
  }
  sessionValidator=()=>{
    let currentTime=new Date().getTime();
    let tokenCreatedTime=sessionStorage.getItem("ls_st");
    if(tokenCreatedTime){
      let diff=moment(currentTime).diff(+tokenCreatedTime,"minutes",true);
      if(diff>60){
        this.setState({sessionExpired:true});
      }
    }
  }
  async eventHandler(authProvider) {
    const res = await authProvider.getAccessToken();
    const gatewayAccessToken = await authProvider.getGatewayAccessToken();
    
    let claims = await authProvider.fetchAccessTokenSilent(authProvider.authParameters.scopes)
   // console.log(claims)
    this.state.prevLocal=claims.accessToken
    localStorage.setItem("token",claims.accessToken);
    sessionStorage.setItem("ls_st",new Date().getTime());
    this.scheduleSessionValidator();
    API.defaults.headers.common['Authorization'] = "Bearer "+claims.accessToken;
    localStorage.setItem("previosToken",claims.accessToken)
    const { accessToken } = res;
    const bearer = "Bearer " + accessToken;
    let config = {
      headers: {
        "Authorization": bearer
      }
    }
    const graphEndpoint = "https://graph.microsoft.com/v1.0/me";
    await axios.get(graphEndpoint, config).then((response) => {
      localStorage.setItem("previosToken",claims.accessToken)
      this.loadAuthUser(claims.idTokenClaims, response);
    
    })
  }

  handleAuthentication = async () => {
    //console.log(window.location.origin)
   
    const basePath = window.location.origin;
    const dev = ["https://iir-dev.lilly.com", "http://localhost:3000"]
    let clientId;
    if (dev.includes(basePath))
      clientId = '2b47dde9-6b11-4cbd-a4cc-f1d1663ac365';
    else if (basePath === "https://iir-stg.lilly.com")
      clientId = 'f65991a7-763b-4271-9870-e4f47cda0e8b';
    else if (basePath === "https://iir-prod.lilly.com")
      clientId = '84bf4899-fa66-417f-a422-b0c42f170c38';
    const config = {
      clientId: clientId,
      openIdCallback: '/',
      scopes: ['openid', 'profile', 'user.read', 'email'],
      isGatewayScopeRequired: true,
      gatewayScopeType: 'production',
      logLevel: 'INFO',
    }
    const authProvider = new AuthProvider(config);
    
    if (window.location.pathname != "/") {
      localStorage.setItem("localpathname", window.location.pathname + window.location.search)
      if(window.location.search.includes("?search=reviews")){
      localStorage.setItem("tab", "Reviews")}
      if(window.location.search.includes("&id=")){
        let id = window.location.search.split("&id=")[1];
        id = parseInt(id);
        if(id)
        localStorage.setItem("reviewid", id)}
        if(window.location.search.includes("&type=")){
          let id = window.location.search.split("&type=")[1];
          
          if(id)
          localStorage.setItem("type", id)}
  
    }

    const logStatus = await authProvider.isLoggedIn()
    //console.log(logStatus)
    if (logStatus)
    await  this.eventHandler(authProvider)
    authProvider.handlePromiseRedirectCallback(async () => {
    await  this.eventHandler(authProvider)
    })
  }

  checkDefaultRole = async (userId) => {
    try{
      const res = await API.get(`/user/check-if/current-role/exists/${userId}`);
      this.setState({defaultRole: res?.data?.current_role})
      return res?.data?.current_role
    }catch(e){
      console.log("Error in fetching default role: ", e);
    }
  }
  updateDefaultRole = async () => {
    try{
      const currentRole = Object.keys(USER_ROLE).find(key =>
        USER_ROLE[key] === this.state.userInfo.selectedRole);
      const res = await API.post("/user/update/current-role", {
        "current_role": currentRole,
        "system_id": this.state.userInfo.userSystemId,
      });
    } catch(e){
      console.log("Error in updating current role: ", e);
    }
  }
  getAppStatus=async ()=>{
    let showMaintenanceMode = false;
    let maintenanceModeDesc = MAINTENANCE_MODE_DESC;
    try{
      const appStatusRes = await API.get('user/system/app-status');

        if( appStatusRes?.data?.maintenance_mode?.trim().toLowerCase() === 'yes' ){
          showMaintenanceMode = true;
          maintenanceModeDesc = appStatusRes?.data?.description||MAINTENANCE_MODE_DESC;
        }
    }catch(e){
      console.error("Error in fetching App Status: ", e);
      showMaintenanceMode = true;
    }
    finally{      
      if( showMaintenanceMode ){
        this.setState({ 
          maintenanceModeDesc,
          maintenanceModeRedirectFlag: true,
          isLoading:false
        }, ()=> this.props.history.replace("/maintenance-mode") );
      }
    }
    return showMaintenanceMode;
  }
  loadAuthUser = async (user, res) => {
   // console.log("rolesassigned",user)
    if (
      !res ||
      !res.data ||
      !user ||
      !user.roles ||
      user.roles.length === 0)
     {
      this.setState({
        restrictUser: true,
        isLoading: false });
      return;
    }
    const defaultRole = await this.checkDefaultRole(user.uid);

    let userName = res.data,
      firstName = userName.givenName,
      roles = user.roles,
      userId = user.uid,
      lastName = userName.surname,
      emailId = user.preferred_username,
      userRole = [];

    if (roles.indexOf("IIRPortalAdmin_Dev") >= 0 ||
      roles.indexOf("IIRPortalAdmin_QA") >= 0 || roles.indexOf("IIRPortalAdmin") >= 0
    )
      userRole.push("Administrator");

    // if (
    //   roles.indexOf("IIRPortalGCBiomed_Dev") >= 0 ||
    //   roles.indexOf("IIRPortalGCDiabetes_Dev") >= 0 ||
    //   roles.indexOf("IIRPortalGCOncology_Dev") >= 0 ||
    //   roles.indexOf("IIRPortalGCBiomed_QA") >= 0 ||
    //   roles.indexOf("IIRPortalGCDiabetes_QA") >= 0 ||
    //   roles.indexOf("IIRPortalGCOncology_QA") >= 0
    // )
    //   userRole.push("Global Coordinator");
    if (
      roles.indexOf("IIRPortalGCBiomed_Dev") >= 0 ||
      roles.indexOf("IIRPortalGCBiomed_QA") >= 0 || roles.indexOf("IIRPortalGCBiomed") >= 0
    )
      userRole.push("Global Coordinator - Biomeds");

 


 if (
      roles.indexOf("IIRPortalGCDiabetes_Dev") >= 0 ||
      roles.indexOf("IIRPortalGCDiabetes_QA") >= 0 || roles.indexOf("IIRPortalGCDiabetes") >= 0 
    )
      userRole.push("Global Coordinator - Diabetes");
      

 

 if (
      roles.indexOf("IIRPortalGCOncology_Dev") >= 0 ||
      roles.indexOf("IIRPortalGCOncology_QA") >= 0 || roles.indexOf("IIRPortalGCOncology") >= 0
    )
      userRole.push("Global Coordinator - Oncology");
      
      
    if (roles.indexOf("IIRPortalIndustrySponsored_Dev") >= 0 ||
      roles.indexOf("IIRPortalIndustrySponsored_QA") >= 0 ||  roles.indexOf("IIRPortalIndustrySponsored") >= 0
    )
      userRole.push("Industry Sponsored");

    if (roles.indexOf("IIRPortalCoord_Dev") >= 0 ||
      roles.indexOf("IIRPortalCoord_QA") >= 0 || roles.indexOf("IIRPortalCoord") >= 0
    )
      userRole.push("Operational Coordinator");

    if (roles.indexOf("IIRPortalRO_Dev") >= 0 ||
      roles.indexOf("IIRPortal_RO_QA") >= 0 || roles.indexOf("IIRPortalRO") >= 0
    )
      userRole.push("Management User");

    // if (
    //   roles.indexOf("IIRPortalROBioMeds_Dev") >= 0 ||
    //   roles.indexOf("IIRPortalRODiabetes_Dev") >= 0 ||
    //   roles.indexOf("IIRPortalROOncology_Dev") >= 0 ||
    //   roles.indexOf("IIRPortalROBioMeds_QA") >= 0 ||
    //   roles.indexOf("IIRPortalRODiabetes_QA") >= 0 ||
    //   roles.indexOf("IIRPortalROOncology_QA") >= 0
    // )
    //   userRole.push("Read Only");
    if (
      roles.indexOf("IIRPortalROBioMeds_Dev") >= 0 ||
      roles.indexOf("IIRPortalROBioMeds_QA") >= 0 || roles.indexOf("IIRPortalROBioMeds") >= 0
    )
      userRole.push("Read Only - Biomeds");

 


 if (
      roles.indexOf("IIRPortalRODiabetes_Dev") >= 0 ||
      roles.indexOf("IIRPortalRODiabetes_QA") >= 0 || roles.indexOf("IIRPortalRODiabetes") >= 0 
    )
      userRole.push("Read Only - Diabetes");
      

 

 if (
      roles.indexOf("IIRPortalROOncology_Dev") >= 0 ||
      roles.indexOf("IIRPortalROOncology_QA") >= 0 || roles.indexOf("IIRPortalROOncology") >= 0
    )
      userRole.push("Read Only - Oncology");
      
    //console.log(userRole)
   let isMaintnanceMode=await this.getAppStatus();
   if(isMaintnanceMode){
    return false;
   }
    this.setState(
      {
        userInfo: {
          userRolesList: defaultRole ? defaultRole : ( localStorage.getItem("role")? localStorage.getItem("role"):userRole[0] ),
          userFirstName: firstName,
          userLastName: lastName,
          userEmailId: emailId,
          userSystemId: userId,
          adList : roles,
          rolesList: userRole,
          selectedRole: defaultRole ? USER_ROLE[defaultRole] : (localStorage.getItem("role") ? USER_ROLE[localStorage.getItem("role")] : USER_ROLE[userRole[0]])
        }
      }, async()=>{
      await  this.loadLoggedUserDetails()
      if(!defaultRole){
        await this.updateDefaultRole();
      } 
    }
    );
    localStorage.setItem("role", defaultRole ? defaultRole : userRole[0] )

  };

  loadLoggedUserDetails =async () => {
    this.setState({ isLoading: true });
    let { userFirstName, userLastName, userSystemId, userEmailId, userRolesList } = this.state.userInfo;
 try {
      const res =await API.post('user/add', {
        "system_id": userSystemId,
        "email": userEmailId,
        "first_name": userFirstName,
        "last_name": userLastName,
        "current_role": userRolesList
      });
      //console.log(res.data);
      this.setState({ isLoading: false });
      this.setState({ restrictUser: false });
    } catch (e) {
      console.log(e);
    }
    let localpath = localStorage.getItem("localpathname");
    // localStorage.clear();
    localStorage.removeItem("localpathname");
    this.setState({ localpath: localpath ? localpath : "/submissions"});
  };

  componentDidMount() {
    this.handleAuthentication();
  }

  submitRoleChange = (userRole) => {
    // this.state.userInfo.rolesList=userRole;
    localStorage.setItem("role",userRole)
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        userRolesList: userRole,
        role:USER_ROLE[localStorage.getItem("role")],
        selectedRole : USER_ROLE[userRole]
      }
    }
    ,() => {
     // console.log("App.state" , this.state);
      this.updateDefaultRole();
      this.props.history.push("/submissions");}
    )
    
  }

  handleSessionExpiry=()=>{
    /*ls_st: Local Session Start Time */
    this.setState({sessionExpired:false});
    this.clearScheduler();
    sessionStorage.removeItem("ls_st");
    this.handleAuthentication();
  }
  renderMaintnanceMode=()=>{
    let {maintenanceModeRedirectFlag,maintenanceModeDesc}=this.state;
    if( !maintenanceModeRedirectFlag){
      this.props.history.replace('/submissions');
  }

  return <>
    <div className="d-flex align-items-center justify-content-center vw-100 vh-100">
        <span className="fs-2 p-5" style={{fontSize: '2rem'}}>{maintenanceModeDesc}</span>
    </div>
  </>;
  }
  render() {
    const { localpath,sessionExpired,maintenanceModeRedirectFlag } = this.state;

    return this.state.isLoading ? (
      <Loader />
    ) : this.state.restrictUser ? (
      <div className="unauthorized_Error">
        <h1>Unauthorized Access</h1>
        <p>
          Access is denied due to invalid credentials.
          <br />
          You are not authorize to use this application. Please contact your
          Business Admin.
        </p>
      </div>
    ) :
      (<div className="App">
        <ErrorBoundary>
          {/* <Router> */}
          {sessionExpired&&<SessionExpiry handleAuthentication={this.handleSessionExpiry}></SessionExpiry>}
          <Switch>
            <Route 
              path='/maintenance-mode'
              component={this.renderMaintnanceMode}  
            />
            {!maintenanceModeRedirectFlag && this.state.userInfo.userSystemId && <UserContext.Provider value={this.state.userInfo} >
              <Topnav submitRoleChange={this.submitRoleChange} />
              <Container fluid className="content">
                {/* <Switch> */}
                  {/* <Route exact path="/" component={Submission} /> */}

                  <Route exact path='/accounts' component={Accounts} />

                  <Route exact path="/authenticated">
                    <Redirect to={localpath} />
                  </Route>
                  <Route exact path="/">
                    <Redirect to={localpath} />
                  </Route>

                  <Route path="/auth/callback">
                    <Redirect to={localpath} />
                  </Route>

                  <Route exact path="/submissions" 
                  render={(props) => <Submission  userInfo={this.state.userInfo} 
                  {...props} />}
                  // component={Submission}
                   >
                  
                   </Route>

                  <Route path="/submissions/:submissionId" component={SubmissionDetail} />
                  <Route exact path="/toReview" component={ToReview} />
                  <Route exact path="/submit-review" component={()=>{
                    let reviewTabData=sessionStorage.getItem("review-tab-data");
                    let formData={};
                    if(reviewTabData){
                      formData=JSON.parse(atob(reviewTabData));
                    }
                    else{
                      console.error("Invalid data@/submit-review/review-tab-data")
                      throw 'Invalid data';
                    }
                    return <ReviewForm {...formData}></ReviewForm>
                  }} />
                  {/* <Route exact path="/investigators" component={Investigators} /> */}
                  <Route exact path="/investigators" component={InvestigatorsEx} />

                  <Route path="/investigators/:investigatorId" component={InvestigatorDetail} />
                  
                  <Route path="/concept/new" component={NewConcept} />
                  


                  <Route path="/protocol/new" component={NewProtocol} />
                


                  <Route path="/diagnostic/new" component={NewDiagnostic} />

                  <Route path="/translational/new" component={NewTranslational} />

                  <Route path="/diagnosticProposal/details/:diagnosticId" component={DiagnosticDetails} />

                  <Route path="/translation/details/:translationId" component={TranslationDetail} />
                  <Route path="/concept/details/:conceptId" component={ConceptDetail} />

                  <Route path="/proposal/new" component={NewProposal} />

                  <Route path="/delegee/new" component={NewDelegee} />
                  <Route path="/ToReview/details/:submission_id" component={ToReviewDetails} />
                  <Route path="/protocol/details/:protocolId" component={ProtocolDetail} />

                  <Route path="/delegee/details/:delegeeId" component={DelegeeDetail} />
                  <Route path="/studycompound/details/:studyCompoundId" component={StudyCompoundDetails} />

                  <Route path="/proposal/details/:proposalId" component={ProposalDetail} />

                  <Route path="/publication/new" component={NewPublication} />

                  <Route path="/review/new/:submissionId" component={NewReview} />
                  <Route path="/country/details/:countryId" component={CountryDetail}/>
                  <Route path="/state/details" component={StateDetail}/>
                  <Route path="/drugdeliverysite/new" component={NewDrugDeliverySite} />
                  <Route path="/drugoptions/details/:drugdeliverysiteId" component={DrugOptionsDetail} />
                  <Route path="/drugdeliverysite/details/:drugdeliverysiteId" component={DrugDeliverySiteDetail} />
                  <Route path="/enrollment/details/:enrollmentId" component={EnrollmentDetails} />
                  <Route path="/reforecast/details/:reforcastId/:rowIndex" component={ReforecastDetails} />
                  <Route path="/expenses/details/:expensesId" component={ExpensesDetails} />
                  <Route path="/contacts/details/:contactsId" component={ContactDetails} />
                  <Route path="/review/newDropdown" component={ReviewDropdown} />
                  <Route path="/drugOrder/details/:id" component={DrugOrderDetails} />

                  <Route path="/country" component={CountryEx} />
             

          
                  {/* <Route path="/compound" component={Compound} /> */}
                  <Route path="/compound" component={CompoundEx} />

                  <Route path="/compounddetails" component={CompoundDetail} />
                  <Route path="/detailCompound" component={CompoundExistDetail} />
                  <Route path="/publication/details/:publicationId" component={PublicationDetail} />
                  <Route path="/amendmentdetails/:amendmentId/:rowIndex" component ={AmendmentDetail}/>
                  <Route path="/conceptreview/details/:conceptId" component ={ReviewConceptDetail}/>
                  <Route path="/protocolreview/details/:protocolId" component ={ReviewProtocolDetail}/>
                  <Route path="/proposalreview/details/:proposalId" component ={ReviewProposalDetail}/>
                  <Route path="/amendmentreview/details/:amendmentId" component ={ReviewAmendmentDetail}/>
                  <Route path="/publicationreview/details/:publicationId" component ={ReviewPublicationDetail}/>
                  <Route path="/reviewer/details" component ={ReviewerDetails}/>
                  <Route path="/sae/details/:sae_name" component={SaeDetail}/>
                  <Route path="/dashboard-poc" component={Dashboard} />
                  <Route path="/dashboard" component={DashboardFile} />                 
                  <Route path="/reports" component={Dashboard}/>
                  <Route path ="/presentation" component={Presentation}/>
                  <Route path ="/count" component={CountEx}/>
                  <Route path ="/strength" component={StrengthNew}/>
                  <Route path ="/recyclebin" component={RecycleBin}/>
                  <Route path ="/roleaudit" component={RoleAudit}/>
                  <Route path ="/presentations/detail/:presentationId" component={PresentationDetail}/>
                  <Route path ="/counts/detail/:countId" component={CountDetail}/>
                  <Route path ="/strengths/detail/:strengthId" component={StrengthDetail}/>
                  <Route path ="/delegee" component={DelegeeListEx}/>
                  <Route path ="/delegeeList/detail/:delegeeId" component={DelegeeListDetail}/>
                  <Route path='/approvalHistory/detail/:approvalId' component={ApprovalHistoryDetail}/>
                {/* </Switch> */}
              </Container>
            </UserContext.Provider>
            }
            <Footer />
                
          {/* </Router> */}
          </Switch>
        </ErrorBoundary>
      </div>
      );
  }
}

export default withRouter(App);
