import swal from 'sweetalert';
import API from '../api/apis';
import { DUPLICATE_FILE_MESSAGE, DUPLICATE_FILE_MESSAGE_DELETED } from '../consts';
import Papa from 'papaparse'
import { Button } from 'react-bootstrap';
export function dateTimeformat(ISODate) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const now = new Date(ISODate);
    const date = (now.getDate()+"" ).padStart(2,0) + "/" + months[now.getMonth()] + "/" + now.getFullYear();
    const time = now.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit' });
    return date + " " + time;
}
export function dateStringformatEx(ISODate) {
    
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if(!ISODate) return "";
    let now=null;
    if (ISODate instanceof Date) {
        now = ISODate;
        let isoStringDate = (ISODate.getDate()+"" ).padStart(2,0)+ "/" + months[ISODate.getMonth()] + "/" + ISODate.getFullYear();
        return isoStringDate;
    }
    else {
        now = new Date(ISODate.split('T')[0]);
    }    
    let date = (now).toISOString().split("T");
    let date2 = date[0].split("-")
    const date1 = date2[2] + "/" + months[parseInt(date2[1]) - 1] + "/" + date2[0];
    return date1;
}
export function dateStringformat(ISODate) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


    const now = new Date(ISODate.split('T')[0]);
    let date = (now).toISOString().split("T");
    let date2 = date[0].split("-")
    const date1 = date2[2] + "/" + months[parseInt(date2[1]) - 1] + "/" + date2[0];
    return date1;
}
export function dateChange(date) {
    if( date === null ){
        return null;
    }
    let now = new Date(date);
    const dateVal = now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + (now.getDate() + "").padStart(2, 0) + 'T00:00:00.000Z';
    return dateVal;
}
export function dateTimeStringformat(ISODate) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const now = new Date(ISODate);
    const date = (now.getDate()+"" ).padStart(2,0)+ "/" + months[now.getMonth()] + "/" + now.getFullYear();
    const time = now.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit',hour12:true });
    return date+" "+time;
}
export function ddmmmyyyyformat(ISODate) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const now = new Date(ISODate);
    const date = (now.getDate()+"" ).padStart(2,0) + months[now.getMonth()]  + now.getFullYear();
    return date
}
export function timeformat(ISODate) {
    const now = new Date(ISODate);
    const time = now.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit' });
    return time;
}
export function dateformat(ISODate) {
    const now = new Date(ISODate);
    const date = now.toLocaleDateString();

    return date;
}
export function dateTimeString(ISODate) {
    const now = new Date(ISODate);
    const date = now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);
    const time = ("0" + (now.getHours())).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2);
    return date + "T" + time;
}
export async function getUserInfo(sysId) {
    if (sysId) {
        try {
            const res = await API.get(`user/systemid/bysystemid/${sysId}`);
            if (res.data) {
                return `${res.data.first_name} ${res.data.last_name}`
            }
            return "";
        } catch (e) {
            return ""
        }
    }
    return "";
}

export function getDateDifferenceMonth(d1, d2) {
    if (d1 === null || d2 === null || d1?.length === 0 || d2?.length === 0)
        return "";

    const d1Date = new Date(d1 + "T00:00");
    const d2Date = new Date(d2 + "T00:00");
    const month_d1 = d1Date.getMonth() + 1;
    const year_d1 = d1Date.getFullYear();
    const month_d2 = d2Date.getMonth() + 1;
    const year_d2 = d2Date.getFullYear();
    if (d1Date > d2Date)
        return (month_d1 + 12 * (year_d1 - year_d2) - month_d2).toString();
    else
        return (month_d2 + 12 * (year_d2 - year_d1) - month_d1).toString();
}
export async function uploadFile(selectedFile, fileList, uploadFileFunction) {
    let data = fileList.filter((e) => e.key.split("\/").pop() === selectedFile.name);
    if (data.length === 0) {
        await uploadFileFunction();
        return;
    }
    let message_text = (data[0].is_deleted === true) ? DUPLICATE_FILE_MESSAGE_DELETED : DUPLICATE_FILE_MESSAGE;
    swal({
        title: "Are you sure?",
        text: message_text,
        icon: "warning",
        buttons: true,
        dangerMode: false,
        buttons: {
            cancel: {
                text: "Go Back",
                value: false,
                visible: true,
                className: "",
                closeModal: true,
            },
            confirm: {
                text: "Replace",
                value: true,
                visible: true,
                className: "",
                closeModal: true
            }
        }
    })
        .then(async (replace) => {
            if (replace) {
                await uploadFileFunction(true);
            }
        });
}
export function getSubmissionText(submission_id){
    return submission_id?`Submission-${(submission_id + "").padStart(4, '0')}`:"";
}
export function getPrintViewTextBox(field,isPrintMode){
    let fieldPrintMode=false;
    if(isPrintMode && field.type==="text"){
        fieldPrintMode=true;
    }
    return {type:fieldPrintMode?"textarea":field.type}
}

export const fetchWorkflowToggle = async (userSystemId) => {
    try{
      const res = await API.get(`enrollmentOptimization/userSettings/fetch/${userSystemId}`);
      if(res?.data){
        return res?.data?.new_workflow_toggle;
      }
    }
    catch(e) {
      //console.log(e);
    }
    return true;
  }
/*
data : [] Array of objects
fileName:csv file name,
callback : callback function > will return status and error  if any
*/
export const downloadCSV=async(data,fileName,callback)=>{   
    let status=true;
    let error=null;
    try{
        var csv = Papa.unparse(data);
        var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        var csvURL =  null;
        if (navigator.msSaveBlob)
        {
            csvURL = navigator.msSaveBlob(csvData, 'download.csv');
        }
        else
        {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `${fileName||"[No Name]"}.csv`);
        tempLink.click();
    }
    catch(exp){
        console.error("CSV Download Failed:",exp);
        error=exp;
        status=false;
    }
    if(callback){
        callback(status,error);
    }
   

}
/*Disabling Old Workflow Actions */
export const enableWFActions=()=>{  
    let isOldWorkflow=false;
    let oldWorkflowPaths=[
    "review/new","concept/details",
    "proposal/new","diagnostic/new",
    "translational/new","protocol/new",

    "publication/details", "proposal/details", "protocol/details", "amendmentdetails/", "publicationreview/details", "amendmentreview/details", "protocolreview/details", "proposalreview/details", "conceptreview/details", "drugorder/details", "reviewer/details", "translation/details", "diagnosticproposal/details",

    "publication/details","enrollment/details",
    "expenses/details",
    "drugdeliverysite/details",
    "drugoptions/details",
    "contacts/details",
    "delegee/details",
    "studycompound/details",
    "reforecast/details"

    ]
    /*Submission Details */
    let path=window.location.pathname;
    let uniquePaths=path.split("/").filter(p=>p.trim().length>0);
    uniquePaths=uniquePaths.map(p=>p.toLowerCase());
    isOldWorkflow=uniquePaths.includes("submissions")&&uniquePaths.length>1;
    
    /*Other Paths */
    oldWorkflowPaths.forEach(p=>{
        if(path.toLowerCase().indexOf(p)>=0){
            isOldWorkflow=true;
        }
    });

    /*Enable if not old flow */
    return !isOldWorkflow;
}
export const renderTableIcons=(caption,callback,icon="add",visible=true,params=null)=>{
    return visible&&<div className="d-flex align-items-center justify-content-center">
        <Button size="sm" 
                onClick={() => callback(params)} 
                className="d-flex align-items-center ml-2" 
                variant="outline-dark">
                    <span className="material-icons mr-1 m-0">{icon}</span>
                    <span style={{ fontSize: ' 0.75rem',textTransform:'uppercase' }}>{caption}</span>
        </Button>
    </div>
}