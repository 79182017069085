import { Roarr as log } from 'roarr';

const createLogger = (providedLogLevel) => {
  // fix for IE - undefined globalThis
  // eslint-disable-next-line no-var
  var globalThis;
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-global-assign,no-restricted-globals
    globalThis = self;
  } else {
    // eslint-disable-next-line no-global-assign
    globalThis = global;
  }

  globalThis.ROARR = globalThis.ROARR || {};

  globalThis.ROARR.write = (message) => {
    const logLevels = {
      TRACE: 10,
      DEBUG: 20,
      INFO: 30,
      WARN: 40,
      ERROR: 50,
      FATAL: 60,
    };

    const logLevel = providedLogLevel;

    const payload = JSON.parse(message);
    if (payload.context.logLevel >= logLevels[logLevel]) {
      // eslint-disable-next-line no-console
      console.log(payload);
    }
  };

  const Logger = log.child({
    // the current instance of a Roarr logger.
    module: 'spa_auth',
  });

  return Logger;
};

export { createLogger };
