import React,{useEffect,useState,useCallback} from 'react'
import DatatTableEx from '../../../common/DataTableEx'
import API from '../../../../api/apis'
import moment from 'moment'
import { GLOBAL_DATE_FORMAT, iirDateFormat } from '../../../../utility/dateHelper'
const columns=[
    {
        dataField: 'id',
        text: 'id',
        sort: true,
        hidden:true,
        csvExport: false ,
        excludeFilter:true

    },
    {
        dataField: 'happend_at',
        text: 'Created Date',
        sort: true,
        sortValue: (cell, row) => moment(row["happend_at"],false).toDate()
    },
    {
        dataField: 'display_text',
        text: 'Field',
        sort: true,
    },
    {
        dataField: 'userName',
        text: 'User',
        sort: true,
    },
    {
        dataField: 'old_value',
        text: 'Original Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.old_value != null ? (row.old_value) : ""}
            </>
        }
    },
    {
        dataField: 'new_value',
        text: 'New Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.new_value != null ? (row.new_value) : ""}
            </>
        }
    },
    {
        dataField: 'row_type_text',
        text: 'Row Type',
        sort: true,
       
    },
]
export default ({submissionId})=>{
    const [source,setSource]=useState([]);
    useEffect(()=>{
        getHistoryData();
    },[submissionId]);
    const formatDate=(d)=>{
        if(d){
            return moment.utc(d).local().format(GLOBAL_DATE_FORMAT);
        }
        return '';
    }
    const getHistoryData=useCallback(async()=>{
        let protocol_fields=await getFieldInformation("Protocol");
        let amendment_fields=await getFieldInformation("Amendment");
        let history_data=await API.get(`reviewsTab/protocolAmendment/history/${submissionId}`);
        let fieldCollection=[...amendment_fields,...protocol_fields];
        let filterdInfo=[];
        if(history_data&&history_data.data){
            (history_data.data||[]).forEach((item,index)=>{
                
                let fieldData=fieldCollection.find(p=>p.key===item.field&&p.type===item.row_type.toLowerCase());
               
                if(fieldData){
                    item.display_text=fieldData.label||item.field;
              
                item.row_type_text=`${item.row_type[0].toUpperCase() + item.row_type.slice(1)} ${item.protocol_version||""}`;
                item.userName=`${item.first_name} ${item.last_name}`
                item.happend_at=(item.action==="INSERT"?formatDate(item.created_at):formatDate(item.updated_at));
                if(['created_at','updated_at'].includes(item.field)){
                    item.old_value=formatDate(item.old_value);
                    item.new_value=formatDate(item.new_value);
                }
                if( ['protocol_version_date', 'decision_date'].includes(item.field) ){
                    item.old_value = iirDateFormat(item.old_value);
                    item.new_value = iirDateFormat(item.new_value);
                }
                filterdInfo.push(item);
                }
            });
        }
        setSource([...new Set(filterdInfo||[])]);

    },[]);
    const getFieldInformation=useCallback(async(type)=>{
        const res = await API.get(`/user/fieldlabels/${type.toLowerCase()}`);
        return (res&&res.data?res.data||[]:[]);
    },[]);

    return <DatatTableEx 
    autoFilter={true}   
    filterChange={()=>{}}
    columns={columns} 
    csvName="history_details" 
    pagination="true" 
    data={source}
    />

}