import React, { Component } from "react";
import InputField from "../common/input-fields/InputField";
import { Row, Col, Dropdown, Modal, Button } from 'react-bootstrap';
import API from "../../api/apis";
import BootstrapModal from "../common/modal/BootstrapModal";
import { isCoordinator } from '../ToReviewDetails/commonFunctions';
import UserContext from '../../context/UserContext'


export default class Enrollment extends Component {
    static contextType = UserContext
    state = {
        enrollmentDetails: [],
        _isCoordinator: false,
    }
    get inputFieldsArray() {
        return [
            {
                label: "Expected Enrollment",
                type: "number",
                placeholder: "Enter Expected Enrollment",
                name: "expected_enrollment",
                helptext: "Number of patients expected to enroll in Trial "
            },
            {
                label: "Current Enrollment (Baseline)",
                type: "text",
                name: "current_enrollment_baseline",
                disabled: true,
                helptext: "Expected current enrollment based on initial projections"
            },
            {
                label: "Current Enrollment Delta",
                type: "text",
                name: "current_enrollment_delta",
                disabled: true,
                helptext: "Percentage of current enrollment compared to estimated current enrollment"
            },
            {
                label: "[EXT] Current Enrollment (Estimated)",
                type: "text",
                name: "current_enrollment_estimated",
                disabled: true,
                helptext: "Expected current enrollment based on current projections"
            },
            {
                label: "Total Percent to Plan",
                type: "text",
                name: "total_percent_to_plan",
                disabled: true,
                helptext: "Percentage of patients enrolled compared to expected enrollment"
            },
            {
                label: "Current Enrollment (Actual)",
                type: "text",
                name: "current_enrollment_actual",
                disabled: true,
                helptext: "Current enrollment"
            }
        ]
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({
            _isCoordinator
        });
        this.getenrollmentdata();
    }
    getenrollmentdata = async () => {
        let res = await API.get(`/enrollmentOptimization/enrollmentOverview/${this.props.submissionId}`)

        let total_percent_to_plan =res.data.total_percent_to_plan!= null? res.data.total_percent_to_plan + "%":"";
        let expected_enrollment = res.data.expected_enrollment;
        let current_enrollment_actual = res.data.current_enrollment_actual;
        let current_enrollment_baseline = res.data.current_enrollment_baseline;
        let current_enrollment_delta = res.data.current_enrollment_delta != null? res.data.current_enrollment_delta+ "%":"";
        let current_enrollment_estimated = res.data.current_enrollment_estimated
        
        this.setState({enrollmentDetails:res.data,total_percent_to_plan,expected_enrollment,current_enrollment_actual,
        current_enrollment_baseline,current_enrollment_delta,current_enrollment_estimated})
       
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            return (
                <div  style={{margin:"auto",width:"60%"}} key={field.label}> <InputField
                    {...field}
                    disabled={field.disabled || !this.state._isCoordinator}
                    value={this.state[field.name]}
                    onChange={this.onInputChange}
                    onSelect={field.onSelect || this.onSelectChange}
                /></div>)
        });
    }
    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }
    onInputChange = (e) => {
       
        let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }
    onSave = async () => {

        try {
            const res = await API.post(`/enrollmentOptimization/enrollmentOverview/update`, { expected_enrollment: this.state.expected_enrollment, submissionId: this.props.submissionId,last_modified_by:this.context.userSystemId });
            this.setState({
                successModal: true,
                success_body: "Updated successfully"
            })
            this.getenrollmentdata()
        }
        catch (err) {
            this.setState({ errorModal: true })
        }


    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    render() {
        return (
            <div >
                <div className="d-flex flex-grow-1 justify-content-end align-items-center mr-4">
                    {this.state._isCoordinator && <Button variant="success" onClick={() => this.onSave()} className="d-flex align-items-center"><span class="material-icons">
                        save
                    </span>SAVE</Button>}</div>
                <div>{this.renderInputFields()}</div>

                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body={this.state.success_body}
                        changeShowState={this.changeShowState}
                        buttonText="Ok"
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An Error has Occurred!please try again"
                        changeShowState={this.changeShowState}
                    />}
            </div>
        )
    }
}