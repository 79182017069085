export const LINE_OF_THERAPY = [
                                "None",
                                "1st Line",
                                "2nd Line",
                                "3rd Line +",
                                "Adjuvant",
                                "Maintenance",
                                "Metastatic",
                                "Neoadjuvant",
                                "Perioperative",
                                "Other"
                            ];

export const APPROVED ="Approved";

export const DECLINED ="Declined";

export const CLINICAL_TRIAL_PHASE = [
                                {
                                    label: "I",
                                    value: "I"
                                },
                                {
                                    label: "II",
                                    value: "II"
                                },
                                {
                                    label: "III",
                                    value: "III"
                                },
                                {
                                    label: "IV",
                                    value: "IV"
                                } 
                            ];

export const STUDY_PRIORITY = [
                                "None",
                                "High",
                                "Medium",
                                "Low"
                            ];

export const TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS = {
    "None": {
        "None": [
            "None"
        ]
    },
    "Clinical Trial": {
        "None": [
            "None"
        ],
        "Concept": [
            "None",
            "In Review",
            "On Hold",
            "Feedback",
            "Declined",
            "Withdrawn"
        ],
        "LOC": [
            "None",
            "On Hold",
            "Not Applicable",
            "Declined",
            "Withdrawn"
        ],
        "Proposal": [
            "None",
            "Draft",
            "In Review",
            "On Hold",
            "Conditionally Approved",
            "Feedback",
            "Submitted",
            "Declined",
            "Withdrawn"
        ],
        "Protocol": [
            "None",
            "Draft",
            "In Review",
            "On Hold",
            "Feedback",
            "Submitted",
            "Declined",
            "Withdrawn"
        ],
        "Study Readiness": [
            "None",
            "On Hold",
            "Budget",
            "Contracting",
            "Drug Shipping",
            "Declined",
            "Withdrawn"
        ],
        "Active Trial": [
            "None",
            "On Hold",
            "Enrolling",
            "CTE - Awaiting FSR",
            "CTE - Pts on Treatment",
            "CTE - Pts on Followup",
            "Feedback"
        ],
        "Study Closeout": [
            "None",
            "Not Applicable"
        ],
        "Completed": [
            "None",
            "Not Applicable"
        ],
        "Declined": [
            "None",
            "Not Applicable"
        ],
        "Withdrawn": [
            "None",
            "Not Applicable"
        ]
    },
    "Diagnostic Research": {
        "None": [
            "None"
        ],
        "Concept": [
            "None",
            "In Review",
            "On Hold",
            "Feedback",
            "Declined",
            "Withdrawn"
        ],
        "LOC": [
            "None",
            "On Hold",
            "Not Applicable",
            "Declined",
            "Withdrawn"
        ],
        "Proposal": [
            "None",
            "Draft",
            "In Review",
            "On Hold",
            "Conditionally Approved",
            "Feedback",
            "Submitted",
            "Declined",
            "Withdrawn"
        ],
        "Study Readiness": [
            "None",
            "On Hold",
            "Budget",
            "Contracting",
            "Drug Shipping",
            "Declined",
            "Withdrawn"
        ],
        "Active Trial": [
            "None",
            "On Hold",
            "Enrolling",
            "CTE - Awaiting FSR",
            "CTE - Pts on Treatment",
            "CTE - Pts on Followup",
            "Feedback"
        ],
        "Study Closeout": [
            "None",
            "Not Applicable"
        ],
        "Completed": [
            "None",
            "Not Applicable"
        ],
        "Declined": [
            "None",
            "Not Applicable"
        ],
        "Withdrawn": [
            "None",
            "Not Applicable"
        ]
    },
    "Translational Research": {        
        "None": [
            "None"
        ],
        "Concept": [
            "None",
            "In Review",
            "On Hold",
            "Feedback",
            "Declined",
            "Withdrawn"
        ],
        "LOC": [
            "None",
            "On Hold",
            "Not Applicable",
            "Declined",
            "Withdrawn"
        ],
        "Proposal": [
            "None",
            "Draft",
            "In Review",
            "On Hold",
            "Conditionally Approved",
            "Feedback",
            "Submitted",
            "Declined",
            "Withdrawn"
        ],
        "Protocol": [
            "None",
            "Draft",
            "In Review",
            "On Hold",
            "Feedback",
            "Submitted",
            "Declined",
            "Withdrawn"
        ],
        "Study Readiness": [
            "None",
            "On Hold",
            "Budget",
            "Contracting",
            "Drug Shipping",
            "Declined",
            "Withdrawn"
        ],
        "Active Trial": [
            "None",
            "On Hold",
            "Enrolling",
            "CTE - Awaiting FSR",
            "CTE - Pts on Treatment",
            "CTE - Pts on Followup",
            "Feedback"
        ],
        "Study Closeout": [
            "None",
            "Not Applicable"
        ],
        "Completed": [
            "None",
            "Not Applicable"
        ],
        "Declined": [
            "None",
            "Not Applicable"
        ],
        "Withdrawn": [
            "None",
            "Not Applicable"
        ]
    },
    "Preclinical/Research": {        
        "None": [
            "None"
        ],
        "Concept": [
            "None",
            "In Review",
            "On Hold",
            "Feedback",
            "Declined",
            "Withdrawn"
        ],
        "LOC": [
            "None",
            "On Hold",
            "Not Applicable",
            "Declined",
            "Withdrawn"
        ],
        "Proposal": [
            "None",
            "Draft",
            "In Review",
            "On Hold",
            "Conditionally Approved",
            "Feedback",
            "Submitted",
            "Declined",
            "Withdrawn"
        ],
        "Protocol": [
            "None",
            "Draft",
            "In Review",
            "On Hold",
            "Feedback",
            "Submitted",
            "Declined",
            "Withdrawn"
        ],
        "Study Readiness": [
            "None",
            "On Hold",
            "Budget",
            "Contracting",
            "Drug Shipping",
            "Declined",
            "Withdrawn"
        ],
        "Active Trial": [
            "None",
            "On Hold",
            "Enrolling",
            "CTE - Awaiting FSR",
            "CTE - Pts on Treatment",
            "CTE - Pts on Followup",
            "Feedback"
        ],
        "Study Closeout": [
            "None",
            "Not Applicable"
        ],
        "Completed": [
            "None",
            "Not Applicable"
        ],
        "Declined": [
            "None",
            "Not Applicable"
        ],
        "Withdrawn": [
            "None",
            "Not Applicable"
        ]
    }
}

export const REQUEST_TYPE = [
    "Investigator Initiated Research (IIR)",
    "Externally Sponsored Collaborative Research (ESCR)",
    "Consortium Collaboration",
    "Industry Sponsored"
]
export const INTERVENTIONAL_STUDY_CHECK = [
    "Consortium Collaboration",
    "Translational Research",
    "ESCR"
]

export const SECONDARY_REQUEST_TYPE = [
    "Diagnostic",
    "Translational",
    "Drug Interventional",
    "Retrospective Analysis",
    "Non-drug Interventional",
    "Pre-Clinical",
    "ExIST"
]

export const THERAPEUTIC_AREA = [
    "None",
    "Oncology",
    "Diabetes & Obesity",
    "Immunology",
    "Neurodegenerative Diseases",
    "Headache & Pain"
]

export const INDICATIONS = [
    {value: "Biliary tract", label: "Biliary tract"},
    {value: "Bladder", label: "Bladder"},
    {value: "Breast", label: "Breast"},
    {value: "Cervical", label: "Cervical"},
    {value: "Cholangiocarcinoma", label: "Cholangiocarcinoma"},
    {value: "CNS Malignancies", label: "CNS Malignancies"},
    {value: "CRC", label: "CRC"},
    {value: "Endometrial", label: "Endometrial"},
    {value: "Esophageal", label: "Esophageal"},
    {value: "Gastric/GEJ ", label: "Gastric/GEJ "},
    {value: "GU", label: "GU"},
    {value: "Gyn", label: "Gyn"},
    {value: "HCC", label: "HCC"},
    {value: "Head & Neck", label: "Head & Neck"},
    {value: "Hematologic", label: "Hematologic"},
    {value: "IO", label: "IO"},
    {value: "Melanoma", label: "Melanoma"},
    {value: "Mesothelioma", label: "Mesothelioma"},
    {value: "Neuroendocrine", label: "Neuroendocrine"},
    {value: "NSCLC", label: "NSCLC"},
    {value: "Other", label: "Other"},
    {value: "Ovarian", label: "Ovarian"},
    {value: "Pancreas", label: "Pancreas"},
    {value: "Pediatric", label: "Pediatric"},
    {value: "Prostate", label: "Prostate"},
    {value: "Renal cell", label: "Renal cell"},
    {value: "Sarcoma", label: "Sarcoma"},
    {value: "SCLC", label: "SCLC"},
    {value: "Solid Tumors", label: "Solid Tumors"},
    {value: "Thoracic", label: "Thoracic"}
]

export const DOSING_DSCHEDULE = [
                    "None",
                    "2 times a day (BID)",
                    "2 times a month (2XM)",
                    "2 times a week (2XW)",
                    "3 times a day (TID)",
                    "3 times a month (3XM)",
                    "3 times a week (3XW)",
                    "4 times a day (QID)",
                    "4 times a month (4XM)",
                    "4 times a week (4XW)",
                    "5 times a month (5XM)",
                    "5 times a week (5XW)",
                    "6 times a month (6XM)",
                    "6 times a week (6XW)",
                    "7 times a week (7XW)",
                    "As needed (PRN)",
                    "At Bedtime (HS)",
                    "Before study drug (AIP)",
                    "Continuous Infusion (CONINF)",
                    "Every 12 Hours (Q12H)",
                    "Every 2 Hours (Q2H)",
                    "Every 2 Weeks (Q2W)",
                    "Every 3 Weeks (Q3W)",
                    "Every 4 Hours (Q4H)",
                    "Every 4 Weeks (Q4W)",
                    "Every 6 Hours (Q6H)",
                    "Every 6 Weeks (Q6W)",
                    "Every 7 Weeks (Q7W)",
                    "Every 8 Hours (Q8H)",
                    "Every 8 Weeks (Q8W)",
                    "Every Afternoon (OPM)",
                    "Every Cycle (QCE)",
                    "Every Hour (QH)",
                    "Every Morning (QAM)",
                    "Every Night (QN)",
                    "Every Evening (QPME)",
                    "Every other Day (QOD)",
                    "Once a Day (QD)",
                    "Once a Month (1XM)",
                    "Once a Week (1XM)",
                    "Once every 6 Months (1Q6XM)",
                    "One Dose (1X)",
                    "Single Dose Multiple Times (SDOSMT)",
                    "Variable Dosing (VARDOS)",
                    "Other"
]



export const US = "US";
export const CANADA ="CA";
export const CHINA = "CN";
export const JAPAN = "JP" ;
export const DUPLICATE_FILE_MESSAGE = 'File with the same name already exists! Uploading the file will replace the existing version.';
export const DUPLICATE_FILE_MESSAGE_DELETED = 'File with same name already exists in Recycle bin, do you want to replace that? '
export const OPERATOROPTIONS ={
    number :  ["Not Equals To","Equals To","Greater Than","Less Than","Greater Than Equals To","Less Than Equals To"],
    boolean : ["Not Equals To","Equals To"],
    text : ["Not Equals To","Equals To", "Contains", "Does Not Contain","Starts With"],
    dropdown : ["Not Equals To","Equals To", "In","Not In"],
    date : ["Not Equals To","Equals To"],
}

export const MAINTENANCE_MODE_DESC = "The portal is currently under maintenance. Please check back later.";