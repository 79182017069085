import React, { Component } from 'react';
import { Accordion, Button as ButtonEx, Card, Col, Container, Row, Modal } from 'react-bootstrap';
import API from '../../api/apis';
import Charts from '../../components/common/Charts/Charts'
import InputField from '../common/input-fields/InputField';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { getUserInfo,dateTimeformat, dateStringformat } from '../../utility/helperFunctions';
import UserContext from '../../context/UserContext';
import { isCoordinator } from '../ToReviewDetails/commonFunctions';
import Button from "../common/Button";

export default class ReforecastModal extends Component {
    static contextType =UserContext ;
    state = {
        is_Coordinator:false,
        access: false,
        showGraph: [],
        showLoader: false,
        submission: "Submission-"+this.props.submissionId,
        submissionId: this.props.submissionId,
        reforcastTitle: "",
        isLoader: true,
        approval: "Awaiting Review",
        fpv: null,
        decisionDate: null,
        fpet: null,
        lpet: null,
        fsr: null,
        lpetx: null,
        lpv: null,
        enableFields:true
    }
    get inputFieldsArray() {
        return [{
            label: "Reforecast Title",
            type: "text",
            name: "reforcastTitle",
            disabled: true,
            edit: true

        },
        {
            label: "[EXT] Quarterly Enrollment",
            type: "number",
            min: "0",
            name: "quarterly_enrollment",
        },
        {
            label: "Approval",
            type: "dropdown",
            placeholder: "Awaiting Review",
            name: "approval",
            options: [
                "None",
                "Awaiting Review",
                "Approved",
                "Declined",
                "Under Review"
            ],
        },
        {
            label: "[EXT] FPV",
            name: "fpv",
            type: "date"
        },
        {
            label: "Decision Date",
            name: "decisionDate",
            type: "date"
        }, {
            label: "[EXT] FPET",
            name: "fpet",
            type: "date"
        }, {
            label: "[EXT] LPET",
            name: "lpet",
            type: "date"
        }, {
            label: "[EXT] LPETx",
            name: "lpetx",
            type: "date"
        }, {
            label: " [EXT] LPV",
            name: "lpv",
            type: "date"
        }, {
            label: "[EXT] FSR",
            name: "fsr",
            type: "date"
        },
        {
            label: "Lilly Feedback",
            type: "textarea",
            name: "feedback",
            // onChange: this.onEditorChange
        },
        {
            label: "[EXT] Investigator Rationale",
            type: "textarea",
            name: "investigator_rationale",
            // onChange: this.onEditorChange
        },
        {
            label: "Submission",
            type: "text",
            name: "submission",

        },
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className: "createUpdateByfontsize",
            disabled: true,
            edit: true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className: "createUpdateByfontsize",
            disabled: true,
            edit: true
        }
        ];
    }
    componentDidMount = async () => {
        let res = await API.post(`/enrollmentOptimization/graph/details`, { submission_id: this.props.submissionId })
        console.log("response", res.data)
        this.setState({ showGraph: res.data })
        const { viewEditData, viewEditFlag } = this.props;
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator ,access:_isCoordinator});
       
        if (viewEditFlag === "edit") {
            viewEditData["fpv"] = viewEditData.fpv != "" && viewEditData.fpv != null ?
            dateStringformat(viewEditData.fpv) : null;
            viewEditData["decisionDate"] = viewEditData.decisionDate != "" && viewEditData.decisionDate != null ?
            dateStringformat(viewEditData.decisionDate) : null;
            viewEditData["fpet"] = viewEditData.fpet != "" && viewEditData.fpet != null ?
            dateStringformat(viewEditData.fpet) : null;
            viewEditData["lpet"] = viewEditData.lpet != "" && viewEditData.lpet != null ?
            dateStringformat(viewEditData.lpet) : null;
            viewEditData["fsr"] = viewEditData.fsr != "" && viewEditData.fsr != null ?
            dateStringformat(viewEditData.fsr) : null; 
            viewEditData["lpetx"] = viewEditData.lpetx != "" && viewEditData.lpetx != null ?
            dateStringformat(viewEditData.lpetx) : null;
            viewEditData["lpv"] = viewEditData.lpv != "" && viewEditData.lpv != null ?
            dateStringformat(viewEditData.lpv) : null;
            viewEditData["createdby"] =viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by)+"  "+ dateTimeformat(viewEditData.createdAt) : "";

           viewEditData["updatedby"] =viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData["createdby"] ;
           viewEditData["reforcastTitle"] =viewEditData.reforecast_title||'';
        viewEditData["submission"]="Submission-"+this.props.submissionId

            this.setState({
               
                ...this.state,
                isLoader:false,
                ...viewEditData,
                enableFields:true
            })
        }
       else{
        this.setState({
            isLoader:false,
            enableFields:false
        })
       } 

    }
    componentDidUpdate(prevProps){
        if( (prevProps.errorModal !== this.props.errorModal) && (this.props.errorModal === true) ){
            this.setState({ showLoader: false, isIUDOperation: false });
        }
    }
    enableFields=()=>{
        this.setState({enableFields:false})
    }
    onInputChange = (e, eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
                hasChanged: true
            });
        }
        else {
            this.setState({
                [e.target.name]: e.target.value,
                patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
                hasChanged: true
            });
        }
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    formValidation = () => {
        let is_valid = true;
        // const initialValues = {
        //    quarterly_enrollment: this.state.quarterly_enrollment
        // }
        const errors = {};
        let customerror = {};
        if (this.state.quarterly_enrollment < 0) {
            customerror["quarterly_enrollment"] = "Negative Number is not allowed";


            this.setState({
                error: { ...errors },
                customerror: { ...customerror }
            });
            return false;
        }
        else {
            this.setState({
                error: { ...errors },
                customerror: { ...customerror }
            });
        }

        return is_valid;
    }
    onSave = () => {
        if (this.formValidation()) {
            this.setState({ showLoader: true, isIUDOperation: true }, () => {
                this.props.handleReforecastSave(this.state);
            });
        }
    }
    onUpdate = () => {
        if (this.formValidation()) {
            this.setState({ showLoader: true, isIUDOperation: true }, () => {
                this.props.handleReforecastUpdate(this.state);
            });
        }
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }
    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            lillyFeedback: { ...this.state.lillyFeedback, [e]: this.getHtmlFromEditorValue(val) }
        });
    };
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;

            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        disabled={field.disabled||this.state.enableFields}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e) => this.onInputChange(e, field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    render() {
        const { handleModalShowHide,addReforecast } = this.props;
        const { showLoader, isIUDOperation } = this.state;
        let loaderConfig={};
        if(isIUDOperation||showLoader){
            loaderConfig={disabled:"disabled"};
        }
        return (
            <Modal className="new-flow-modal"
                show={true}
                size="lg"
                dialogClassName="lg-xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header style={{ border: 0 }} closeButton onClick={() => addReforecast(false)}>
                    <Modal.Title className="contained-modal-title w-100">
                        {this.props.viewEditFlag=="edit"?<h4 > Quarter Reforecast Details</h4>                        
                        
                        :<h4>Adding Enrollment Reforecast</h4>}
 </Modal.Title>

                </Modal.Header><Modal.Body className={['new-flow-modal',this.props.viewEditFlag==="edit"&&this.state.enableFields?"disabled_view":""].join(" ")}>
            
                    {this.state.showGraph && this.state.showGraph.length > 0 &&<div  className="position-relative">
                        <h4   class='text-center w-100 position-absolute' style={{top:'0.50rem',zIndex:'99999'}}>
                        Impact of Reforecast on Enrollment over time</h4>
                        <Charts defaultRange={10} source={this.state.showGraph}></Charts></div>}
                    <br />
<div className="d-flex justify-content-end w-100">
                    {this.state.is_Coordinator&&this.props.viewEditFlag==="edit"&&this.state.enableFields?
                    <ButtonEx {...loaderConfig} size="sm"  className="d-flex align-items-center text-right mr-1" variant="outline-dark"onClick={()=>this.enableFields()}> <span className="material-icons mr-1 m-0">edit
                    </span><span style={{fontSize:' 0.75rem'}}>EDIT REFORECAST</span></ButtonEx>:
                   this.state.is_Coordinator&& this.props.viewEditFlag==="edit"&&<ButtonEx {...loaderConfig} size="sm"  className="d-flex align-items-center mr-1" variant="outline-dark"onClick={this.onUpdate}> <span className="material-icons mr-1 m-0">edit
                    </span><span style={{fontSize:' 0.75rem'}}>SAVE REFORECAST</span></ButtonEx>}</div>
                    <br/>
                    <Row>{this.renderInputFields()}</Row>
                </Modal.Body><Modal.Footer className='border-0'>

                    {
                        this.props.viewEditFlag != "edit" && <Button {...loaderConfig} style="success " className='d-flex  align-items-center p-2' onClick={this.onSave} >
                            <span class="material-icons mr-1 p-0"  >send</span>
                            <span >SUBMIT</span>
                        </Button>
                        }
                    </Modal.Footer></Modal>
        )
    }
}