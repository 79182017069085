/**
   * Function to handle date format(MM-DD-YYYY)
**/
import moment from 'moment';
const dateFormat = (inputFormat) => {
    var date = new Date(inputFormat);
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
        '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
};

/**
   * Function to handle date format(YYYY-MM-DD)
**/

export const yyyymmddFormat = (inputFormat) => {
    let isoStringDate = new Date(inputFormat)
    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    isoStringDate = (isoStringDate.getDate()+"" ).padStart(2,0)+ "/" + months[isoStringDate.getMonth()] + "/" + isoStringDate.getFullYear();
    return isoStringDate;
};

export const convertISOStringMMDDYYYY = (inputFormat) => {
    let isoStringDate = new Date(inputFormat);
    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    isoStringDate = (isoStringDate.getDate()+"" ).padStart(2,0)+ "/" + months[isoStringDate.getMonth()] + "/" + isoStringDate.getFullYear();
   
    return isoStringDate;
};

export const addDaysToDate = (date, days) => {
    if( date === null ){
        return null;
    }
    var res = new Date(date);
   res.setDate(res.getDate() + days);
   const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    res = (res.getDate()+"" ).padStart(2,0)+ "/" + months[res.getMonth()] + "/" + res.getFullYear();
   
    return res;
}
export const formatDate=(dateObj,inputFormat,outFormat)=>{
    let momentObj= null;
    if (dateObj instanceof Date) {
         momentObj = moment(dateObj);
    }
    if (typeof (dateObj) === "string") {
         momentObj = inputFormat?moment(dateObj, inputFormat, false):moment(dateObj);
    }
    if (momentObj&&momentObj.isValid()) {
        return momentObj.format(outFormat || "MM/DD/YYYY");
    }
    return null;
}
export default dateFormat;

