import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { addDaysToDate, convertISOStringMMDDYYYY } from '../../common/dateFormat';

import { getUserInfo, dateStringformat } from '../../../utility/helperFunctions';
import { US, JAPAN, CHINA, CANADA } from '../../../consts';

class UpdateMilestones extends React.Component {
    state = {
        error: false,
        errorMsg: "",
        milestones: {
            submissionId: this.props.submissionDetails?.id,
            protocol_approved_estimate_check: true,
            protocol_received_estimate_check: true,
            budget_complete_estimate_check: this.props.submissionDetails?.concepts[0]?.country_data?.countrycode === US,
            initial_irb_approval_estimate_check: this.props.submissionDetails?.concepts[0]?.country_data?.countrycode === US,
            initial_ct_material_ship_estimate_check: true,
            fpv_estimate_check: true,
            contract_complete_estimate_check: true,
        }
    }
    async componentDidMount() {
        const { concepts: { [0]: { country_data: { countrycode }, therapeutic_area } }, stage, proposal_approval_date, type_of_material_provided,
            protocol_approved_baseline_check, protocol_received_baseline_check, budget_complete_baseline_check,
            initial_irb_approval_baseline_check, initial_ct_material_ship_baseline_check,
            contract_complete_baseline_check, fpv_baseline_check, protocol_approved_baseline,
            protocol_approved_estimate, protocol_received_baseline, protocol_received_estimate,
            budget_complete_baseline, budget_complete_estimate, initial_irb_approval_baseline, initial_irb_approval_estimate,
            initial_ct_material_ship_baseline, initial_ct_material_ship_estimate, contract_complete_baseline, fpv_estimate,
            fpv_baseline, contract_complete_estimate
        } = this.props.submissionDetails;
        const { milestones } = this.state;

        if (countrycode === CHINA || countrycode === CANADA || countrycode === JAPAN) {
            this.setState({
                error: true,
                errorMsg: "This functionality is not available for Canada, China, Japan."
            })
        } else if (countrycode === US) {
            if ((stage === "Study Readiness" || stage === "Protocol") &&
                (therapeutic_area != "Diabetes & Obesity" && therapeutic_area != "None")) {
                if (proposal_approval_date === null || proposal_approval_date === "" || proposal_approval_date === undefined) {
                    this.setState({
                        error: true,
                        errorMsg: "Please Update Proposal Approval."
                    })
                    return;
                }
                var prb = !protocol_received_baseline ? await addDaysToDate(proposal_approval_date, 60) : dateStringformat(protocol_received_baseline),
                    pre = !protocol_received_estimate ? await addDaysToDate(proposal_approval_date, 60) : dateStringformat(protocol_received_estimate),
                    pab = !protocol_approved_baseline ? await addDaysToDate(prb, 90) : dateStringformat(protocol_approved_baseline),
                    pae = !protocol_approved_estimate ? await addDaysToDate(pre, 90) : dateStringformat(protocol_approved_estimate),
                    bcb = !budget_complete_baseline ? await addDaysToDate(pab, 90) : dateStringformat(budget_complete_baseline),
                    bce = !budget_complete_estimate ? await addDaysToDate(pae, 90) : dateStringformat(budget_complete_estimate),
                    ccb = !contract_complete_baseline ? await addDaysToDate(bcb, 100) : dateStringformat(contract_complete_baseline),
                    cce = !contract_complete_estimate ? await addDaysToDate(bce, 100) : dateStringformat(contract_complete_estimate),
                    iiab = !initial_irb_approval_baseline ? await addDaysToDate(ccb, 45) : dateStringformat(initial_irb_approval_baseline),
                    iiae = !initial_irb_approval_estimate ? await addDaysToDate(cce, 45) : dateStringformat(initial_irb_approval_estimate),
                    icmsb = !initial_ct_material_ship_baseline ? await addDaysToDate(iiab, 45) : dateStringformat(initial_ct_material_ship_baseline),
                    icmse = !initial_ct_material_ship_estimate ? await addDaysToDate(iiae, 45) : dateStringformat(initial_ct_material_ship_estimate),
                    fpve = !fpv_estimate ? await addDaysToDate(icmse, 30) : dateStringformat(fpv_estimate);
                this.setState({
                    error: false,
                    milestones: {
                        ...milestones,
                        protocol_received_baseline: prb,
                        protocol_received_estimate: pre,
                        protocol_approved_baseline: pab,
                        protocol_approved_estimate: pae,
                        budget_complete_baseline: bcb,
                        budget_complete_estimate: bce,
                        contract_complete_baseline: ccb,
                        contract_complete_estimate: cce,
                        initial_irb_approval_baseline: iiab,
                        initial_irb_approval_estimate: iiae,
                        initial_ct_material_ship_baseline: icmsb,
                        initial_ct_material_ship_estimate: icmse,
                        fpv_estimate: fpve,
                        protocol_approved_baseline_check,
                        protocol_received_baseline_check,
                        budget_complete_baseline_check,
                        initial_irb_approval_baseline_check,
                        initial_ct_material_ship_baseline_check,
                        contract_complete_baseline_check,
                        fpv_baseline_check: false
                    }
                })
            } else {
                this.setState({
                    error: true,
                    errorMsg: "This functionality can be used when the stage is Protocol or Study Readiness and study is for Oncology and Biomeds."
                })
            }

        } else {
            if (stage === "Study Readiness" || stage === "Protocol") {
                if (proposal_approval_date === null || proposal_approval_date === "" || proposal_approval_date === undefined) {
                    this.setState({
                        error: true,
                        errorMsg: "Please Update Proposal Approval."
                    })
                    return;
                }
                if (type_of_material_provided === null || type_of_material_provided === "") {
                    this.setState({
                        error: true,
                        errorMsg: "Please Enter value in Type of Material."
                    })
                } else {
                    if (type_of_material_provided === "Comm pack with aux sticker") {
                        var prb = !protocol_received_baseline ? await addDaysToDate(proposal_approval_date, 90) : dateStringformat(protocol_received_baseline),
                            pre = !protocol_received_estimate ? await addDaysToDate(proposal_approval_date, 60) : dateStringformat(protocol_received_estimate),
                            pab = !protocol_approved_baseline ? await addDaysToDate(prb, 60) : dateStringformat(protocol_approved_baseline),
                            pae = !protocol_approved_estimate ? await addDaysToDate(pre, 30) : dateStringformat(protocol_approved_estimate),
                            ccb = !contract_complete_baseline ? await addDaysToDate(pab, 150) : dateStringformat(contract_complete_baseline),
                            cce = !contract_complete_estimate ? await addDaysToDate(pae, 90) : dateStringformat(contract_complete_estimate),
                            icmsb = !initial_ct_material_ship_baseline ? await addDaysToDate(ccb, 120) : dateStringformat(initial_ct_material_ship_baseline),
                            icmse = !initial_ct_material_ship_estimate ? await addDaysToDate(cce, 120) : dateStringformat(initial_ct_material_ship_estimate),
                            fpvb = !fpv_baseline ? await addDaysToDate(icmsb, 30) : dateStringformat(fpv_baseline),
                            fpve = !fpv_estimate ? await addDaysToDate(icmse, 30) : dateStringformat(fpv_estimate);
                        this.setState({
                            error: false,
                            milestones: {
                                ...milestones,
                                protocol_approved_baseline: pab,
                                protocol_approved_estimate: pae,
                                protocol_received_baseline: prb,
                                protocol_received_estimate: pre,
                                initial_ct_material_ship_baseline: icmsb,
                                initial_ct_material_ship_estimate: icmse,
                                contract_complete_baseline: ccb,
                                contract_complete_estimate: cce,
                                fpv_estimate: fpve,
                                fpv_baseline: fpvb,
                                protocol_approved_baseline_check,
                                protocol_received_baseline_check,
                                initial_ct_material_ship_baseline_check,
                                contract_complete_baseline_check,
                                fpv_baseline_check

                            }
                        })

                    } else if (type_of_material_provided === "Investigational label") {
                        var prb = !protocol_received_baseline ? await addDaysToDate(proposal_approval_date, 90) : dateStringformat(protocol_received_baseline),
                            pre = !protocol_received_estimate ? await addDaysToDate(proposal_approval_date, 60) : dateStringformat(protocol_received_estimate),
                            pab = !protocol_approved_baseline ? await addDaysToDate(prb, 60) : dateStringformat(protocol_approved_baseline),
                            pae = !protocol_approved_estimate ? await addDaysToDate(pre, 30) : dateStringformat(protocol_approved_estimate),
                            ccb = !contract_complete_baseline ? await addDaysToDate(pab, 150) : dateStringformat(contract_complete_baseline),
                            cce = !contract_complete_estimate ? await addDaysToDate(pae, 90) : dateStringformat(contract_complete_estimate),
                            icmsb = !initial_ct_material_ship_baseline ? await addDaysToDate(ccb, 270) : dateStringformat(initial_ct_material_ship_baseline),
                            icmse = !initial_ct_material_ship_estimate ? await addDaysToDate(cce, 270) : dateStringformat(initial_ct_material_ship_estimate),
                            fpvb = !fpv_baseline ? await addDaysToDate(icmsb, 30) : dateStringformat(fpv_baseline),
                            fpve = !fpv_estimate ? await addDaysToDate(icmse, 30) : dateStringformat(fpv_estimate);
                        this.setState({
                            error: false,
                            milestones: {
                                ...milestones,
                                protocol_approved_baseline: pab,
                                protocol_approved_estimate: pae,
                                protocol_received_baseline: prb,
                                protocol_received_estimate: pre,
                                initial_ct_material_ship_baseline: icmsb,
                                initial_ct_material_ship_estimate: icmse,
                                contract_complete_baseline: ccb,
                                contract_complete_estimate: cce,
                                fpv_estimate: fpve,
                                fpv_baseline: fpvb,
                                protocol_approved_baseline_check,
                                protocol_received_baseline_check,
                                initial_ct_material_ship_baseline_check,
                                contract_complete_baseline_check,
                                fpv_baseline_check
                            }
                        })

                    } else {
                        var prb = !protocol_received_baseline ? await addDaysToDate(proposal_approval_date, 90) : dateStringformat(protocol_received_baseline),
                            pre = !protocol_received_estimate ? await addDaysToDate(proposal_approval_date, 60) : dateStringformat(protocol_received_estimate),
                            pab = !protocol_approved_baseline ? await addDaysToDate(prb, 60) : dateStringformat(protocol_approved_baseline),
                            pae = !protocol_approved_estimate ? await addDaysToDate(pre, 30) : dateStringformat(protocol_approved_estimate),
                            ccb = !contract_complete_baseline ? await addDaysToDate(pab, 150) : dateStringformat(contract_complete_baseline),
                            cce = !contract_complete_estimate ? await addDaysToDate(pae, 90) : dateStringformat(contract_complete_estimate),
                            icmsb = !initial_ct_material_ship_baseline ? await addDaysToDate(ccb, 0) : dateStringformat(initial_ct_material_ship_baseline),
                            icmse = !initial_ct_material_ship_estimate ? await addDaysToDate(cce, 0) : dateStringformat(initial_ct_material_ship_estimate),
                            fpvb = !fpv_baseline ? await addDaysToDate(icmsb, 30) : dateStringformat(fpv_baseline),
                            fpve = !fpv_estimate ? await addDaysToDate(icmse, 30) : dateStringformat(fpv_estimate);
                        this.setState({
                            error: false,
                            milestones: {
                                ...milestones,
                                protocol_approved_baseline: pab,
                                protocol_approved_estimate: pae,
                                protocol_received_baseline: prb,
                                protocol_received_estimate: pre,
                                initial_ct_material_ship_baseline: icmsb,
                                initial_ct_material_ship_estimate: icmse,
                                contract_complete_baseline: ccb,
                                contract_complete_estimate: cce,
                                fpv_estimate: fpve,
                                fpv_baseline: fpvb,
                                protocol_approved_baseline_check,
                                protocol_received_baseline_check,
                                initial_ct_material_ship_baseline_check,
                                contract_complete_baseline_check,
                                fpv_baseline_check
                            }
                        })
                    }
                }
            } else {
                this.setState({
                    error: true,
                    errorMsg: "This functionality can be used when the stage is Protocol or Study Readiness."
                })
            }

        }
    }


    get inputFieldsArray() {
        const { concepts: { [0]: { country_data: { countrycode } } } } = this.props.submissionDetails;
        const { protocol_approved_baseline_check, protocol_approved_estimate_check, protocol_received_baseline_check
            , protocol_received_estimate_check, budget_complete_baseline_check, budget_complete_estimate_check,
            initial_irb_approval_baseline_check, initial_irb_approval_estimate_check, initial_ct_material_ship_baseline_check,
            initial_ct_material_ship_estimate_check, contract_complete_baseline_check,
            fpv_estimate_check, contract_complete_estimate_check, fpv_baseline_check } = this.state.milestones;
        return [{
            label: "",
            type: "checkbox",
            name: "protocol_received_baseline_check",
            display: true,
            disabled: this.props.submissionDetails.protocol_received_baseline_check &&
                this.props.submissionDetails.protocol_received_baseline ? true : false
        }, {
            label: "Protocol Received (Baseline)",
            type: "date",
            name: "protocol_received_baseline",
            disabled: this.props.submissionDetails.protocol_received_baseline_check &&
                this.props.submissionDetails.protocol_received_baseline ?
                true : !protocol_received_baseline_check,
            display: true
        }, {
            label: "",
            type: "checkbox",
            name: "protocol_received_estimate_check",
            display: true,
        }, {
            label: "Protocol Received (Estimate)",
            type: "date",
            name: "protocol_received_estimate",
            disabled: !protocol_received_estimate_check,
            display: true
        }, {
            label: "",
            type: "checkbox",
            name: "protocol_approved_baseline_check",
            display: true,
            disabled: this.props.submissionDetails.protocol_approved_baseline_check &&
                this.props.submissionDetails.protocol_approved_baseline ? true : false
        }, {
            label: "Protocol Approved (Baseline)",
            type: "date",
            name: "protocol_approved_baseline",
            disabled: this.props.submissionDetails.protocol_approved_baseline_check &&
                this.props.submissionDetails.protocol_approved_baseline ?
                true : !protocol_approved_baseline_check,
            display: true
        }, {
            label: "",
            type: "checkbox",
            name: "protocol_approved_estimate_check",
            display: true
        }, {
            label: "Protocol Approved (Estimate)",
            type: "date",
            name: "protocol_approved_estimate",
            disabled: !protocol_approved_estimate_check,
            display: true
        }, {
            label: "",
            type: "checkbox",
            name: "budget_complete_baseline_check",
            display: countrycode === US,
            disabled: this.props.submissionDetails.budget_complete_baseline_check &&
                this.props.submissionDetails.budget_complete_baseline ? true : false
        }, {
            label: "Budget Complete (Baseline)",
            type: "date",
            name: "budget_complete_baseline",
            disabled: this.props.submissionDetails.budget_complete_baseline_check &&
                this.props.submissionDetails.budget_complete_baseline ? true : !budget_complete_baseline_check,
            display: countrycode === US
        }, {
            label: "",
            type: "checkbox",
            name: "budget_complete_estimate_check",
            display: countrycode === US
        }, {
            label: "Budget Complete (Estimate)",
            type: "date",
            name: "budget_complete_estimate",
            disabled: !budget_complete_estimate_check,
            display: countrycode === US
        }, {
            label: "",
            type: "checkbox",
            name: "contract_complete_baseline_check",
            display: true,
            disabled: this.props.submissionDetails.contract_complete_baseline_check &&
                this.props.submissionDetails.contract_complete_baseline ? true : false
        }, {
            label: "Contract Complete (Baseline)",
            type: "date",
            name: "contract_complete_baseline",
            disabled: this.props.submissionDetails.contract_complete_baseline_check &&
                this.props.submissionDetails.contract_complete_baseline ? true : !contract_complete_baseline_check,
            display: true
        }, {
            label: "",
            type: "checkbox",
            name: "contract_complete_estimate_check",
            display: true
        }, {
            label: "Contract Complete (Estimate)",
            type: "date",
            name: "contract_complete_estimate",
            disabled: !contract_complete_estimate_check,
            display: true
        }, {
            label: "",
            type: "checkbox",
            name: "initial_irb_approval_baseline_check",
            display: countrycode === US,
            disabled: this.props.submissionDetails.initial_irb_approval_baseline_check &&
                this.props.submissionDetails.initial_irb_approval_baseline ? true : false
        }, {
            label: "Initial IRB Approval (Baseline)",
            type: "date",
            name: "initial_irb_approval_baseline",
            disabled: this.props.submissionDetails.initial_irb_approval_baseline_check &&
                this.props.submissionDetails.initial_irb_approval_baseline ? true : !initial_irb_approval_baseline_check,
            display: countrycode === US
        }, {
            label: "",
            type: "checkbox",
            name: "initial_irb_approval_estimate_check",
            display: countrycode === US
        }, {
            label: "Initial IRB Approval (Estimate)",
            type: "date",
            name: "initial_irb_approval_estimate",
            disabled: !initial_irb_approval_estimate_check,
            display: countrycode === US
        }, {
            label: "",
            type: "checkbox",
            name: "initial_ct_material_ship_baseline_check",
            display: true,
            disabled: this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                this.props.submissionDetails.initial_ct_material_ship_baseline ? true : false
        }, {
            label: "Initial CT Material Ship (Baseline)",
            type: "date",
            name: "initial_ct_material_ship_baseline",
            disabled: this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                this.props.submissionDetails.initial_ct_material_ship_baseline ? true : !initial_ct_material_ship_baseline_check,
            display: true
        }, {
            label: "",
            type: "checkbox",
            name: "initial_ct_material_ship_estimate_check",
            display: true
        }, {
            label: "Initial CT Material Ship (Estimate)",
            type: "date",
            name: "initial_ct_material_ship_estimate",
            disabled: !initial_ct_material_ship_estimate_check,
            display: true
        },
        {
            label: "",
            type: "checkbox",
            name: "fpv_baseline_check",
            display: countrycode != US,
            disabled: this.props.submissionDetails.fpv_baseline_check &&
                this.props.submissionDetails.fpv_baseline ? true : false
        }, {
            label: "[EXT] FPV (Baseline)",
            type: "date",
            name: "fpv_baseline",
            disabled: this.props.submissionDetails.fpv_baseline_check &&
                this.props.submissionDetails.fpv_baseline ? true : !fpv_baseline_check,
            display: countrycode != US
        },
        {
            type: "blank",
            show: countrycode === US,
            colSize: "6"
        },
        {
            label: "",
            type: "checkbox",
            name: "fpv_estimate_check",
            display: true
        }, {
            label: "[EXT] FPV (Estimate)",
            type: "date",
            name: "fpv_estimate",
            disabled: !fpv_estimate_check,
            display: true
        }
        ];
    }
    checkboxChange = (e) => {
        const { milestones } = this.state
        this.setState({
            milestones: {
                ...milestones,
                [e.target.name]: e.target.checked,
            },
            hasChanged: true
        });
    }

    onInputChange = async (e, eo) => {
        const { milestones } = this.state
        let { name, value, type } = eo;

        if (type === "date") {
            const { concepts: { [0]: { country_data: { countrycode } } }, type_of_material_provided, } = this.props.submissionDetails;
            let todayDate = new Date();
            let selectedDate = (e === null) ? null : new Date(e);
            if ((selectedDate?.getTime() < todayDate?.getTime()) || selectedDate === null) {
                this.setState({
                    milestones: {
                        ...milestones,
                        [eo.name]: e,
                    }
                });
                return false;
            }
            if (countrycode === US) {
                switch (name) {
                    case "protocol_received_baseline":
                        var protocol_approved_baseline = this.props.submissionDetails.protocol_approved_baseline_check &&
                            this.props.submissionDetails.protocol_approved_baseline ?
                            dateStringformat(this.props.submissionDetails.protocol_approved_baseline) : await addDaysToDate(e, 90),
                            budget_complete_baseline = this.props.submissionDetails.budget_complete_baseline_check &&
                                this.props.submissionDetails.budget_complete_baseline ?
                                dateStringformat(this.props.submissionDetails.budget_complete_baseline) :
                                await addDaysToDate(protocol_approved_baseline, 90),
                            contract_complete_baseline = this.props.submissionDetails.contract_complete_baseline_check &&
                                this.props.submissionDetails.contract_complete_baseline ?
                                dateStringformat(this.props.submissionDetails.contract_complete_baseline) :
                                await addDaysToDate(budget_complete_baseline, 100),
                            initial_irb_approval_baseline = this.props.submissionDetails.initial_irb_approval_baseline_check &&
                                this.props.submissionDetails.initial_irb_approval_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_irb_approval_baseline) :
                                await addDaysToDate(contract_complete_baseline, 45),
                            initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                                this.props.submissionDetails.initial_ct_material_ship_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                                await addDaysToDate(initial_irb_approval_baseline, 45);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                protocol_approved_baseline,
                                budget_complete_baseline,
                                contract_complete_baseline,
                                initial_irb_approval_baseline,
                                initial_ct_material_ship_baseline
                            }
                        });
                        break;
                    case "protocol_approved_baseline":
                        var budget_complete_baseline = this.props.submissionDetails.budget_complete_baseline_check &&
                            this.props.submissionDetails.budget_complete_baseline ?
                            dateStringformat(this.props.submissionDetails.budget_complete_baseline) :
                            await addDaysToDate(e, 90),
                            contract_complete_baseline = this.props.submissionDetails.contract_complete_baseline_check &&
                                this.props.submissionDetails.contract_complete_baseline ?
                                dateStringformat(this.props.submissionDetails.contract_complete_baseline) :
                                await addDaysToDate(budget_complete_baseline, 100),
                            initial_irb_approval_baseline = this.props.submissionDetails.initial_irb_approval_baseline_check &&
                                this.props.submissionDetails.initial_irb_approval_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_irb_approval_baseline) :
                                await addDaysToDate(contract_complete_baseline, 45),
                            initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                                this.props.submissionDetails.initial_ct_material_ship_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                                await addDaysToDate(initial_irb_approval_baseline, 45);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                budget_complete_baseline,
                                contract_complete_baseline,
                                initial_irb_approval_baseline,
                                initial_ct_material_ship_baseline
                            }
                        });
                        break;
                    case "budget_complete_baseline":
                        var contract_complete_baseline = this.props.submissionDetails.contract_complete_baseline_check &&
                            this.props.submissionDetails.contract_complete_baseline ?
                            dateStringformat(this.props.submissionDetails.contract_complete_baseline) :
                            await addDaysToDate(e, 100),
                            initial_irb_approval_baseline = this.props.submissionDetails.initial_irb_approval_baseline_check &&
                                this.props.submissionDetails.initial_irb_approval_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_irb_approval_baseline) :
                                await addDaysToDate(contract_complete_baseline, 45),
                            initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                                this.props.submissionDetails.initial_ct_material_ship_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                                await addDaysToDate(initial_irb_approval_baseline, 45);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                contract_complete_baseline,
                                initial_irb_approval_baseline,
                                initial_ct_material_ship_baseline
                            }
                        });
                        break;
                    case "contract_complete_baseline":
                        var initial_irb_approval_baseline = this.props.submissionDetails.initial_irb_approval_baseline_check &&
                            this.props.submissionDetails.initial_irb_approval_baseline ?
                            dateStringformat(this.props.submissionDetails.initial_irb_approval_baseline) :
                            await addDaysToDate(e, 45),
                            initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                                this.props.submissionDetails.initial_ct_material_ship_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                                await addDaysToDate(initial_irb_approval_baseline, 45);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                initial_irb_approval_baseline,
                                initial_ct_material_ship_baseline
                            }
                        });
                        break;
                    case "initial_irb_approval_baseline":
                        var initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                            this.props.submissionDetails.initial_ct_material_ship_baseline ?
                            dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                            await addDaysToDate(e, 45);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                initial_ct_material_ship_baseline
                            }
                        });
                        break;
                    case "protocol_received_estimate":
                        var protocol_approved_estimate = await addDaysToDate(e, 90),
                            budget_complete_estimate = await addDaysToDate(protocol_approved_estimate, 90),
                            contract_complete_estimate = await addDaysToDate(budget_complete_estimate, 100),
                            initial_irb_approval_estimate = await addDaysToDate(contract_complete_estimate, 45),
                            initial_ct_material_ship_estimate = await addDaysToDate(initial_irb_approval_estimate, 45),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                protocol_approved_estimate,
                                budget_complete_estimate,
                                contract_complete_estimate,
                                initial_irb_approval_estimate,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "protocol_approved_estimate":
                        var budget_complete_estimate = await addDaysToDate(e, 90),
                            contract_complete_estimate = await addDaysToDate(budget_complete_estimate, 100),
                            initial_irb_approval_estimate = await addDaysToDate(contract_complete_estimate, 45),
                            initial_ct_material_ship_estimate = await addDaysToDate(initial_irb_approval_estimate, 45),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                budget_complete_estimate,
                                contract_complete_estimate,
                                initial_irb_approval_estimate,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "budget_complete_estimate":
                        var contract_complete_estimate = await addDaysToDate(e, 100),
                            initial_irb_approval_estimate = await addDaysToDate(contract_complete_estimate, 45),
                            initial_ct_material_ship_estimate = await addDaysToDate(initial_irb_approval_estimate, 45),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                contract_complete_estimate,
                                initial_irb_approval_estimate,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "contract_complete_estimate":
                        var initial_irb_approval_estimate = await addDaysToDate(e, 45),
                            initial_ct_material_ship_estimate = await addDaysToDate(initial_irb_approval_estimate, 45),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                initial_irb_approval_estimate,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "initial_irb_approval_estimate":
                        var initial_ct_material_ship_estimate = await addDaysToDate(e, 45),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "initial_ct_material_ship_estimate":
                        var fpv_estimate = await addDaysToDate(e, 30);
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                fpv_estimate
                            }
                        });
                        break;
                    default:
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e
                            }
                        });
                        break;
                }
                return false;
            }
            if (countrycode != CHINA && countrycode != JAPAN || countrycode != CANADA) {
                switch (name) {
                    case "protocol_received_baseline":
                        var protocol_approved_baseline = this.props.submissionDetails.protocol_approved_baseline_check &&
                            this.props.submissionDetails.protocol_approved_baseline ?
                            dateStringformat(this.props.submissionDetails.protocol_approved_baseline) : await addDaysToDate(e, 60),
                            contract_complete_baseline = this.props.submissionDetails.contract_complete_baseline_check &&
                                this.props.submissionDetails.contract_complete_baseline ?
                                dateStringformat(this.props.submissionDetails.contract_complete_baseline) :
                                await addDaysToDate(protocol_approved_baseline, 150),
                            initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                                this.props.submissionDetails.initial_ct_material_ship_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                                await addDaysToDate(contract_complete_baseline,
                                    type_of_material_provided === "Comm pack with aux sticker" ? 120 :
                                        type_of_material_provided === "Investigational label" ? 270 : 0),
                            fpv_baseline = this.props.submissionDetails.fpv_baseline_check &&
                                this.props.submissionDetails.fpv_baseline ?
                                dateStringformat(this.props.submissionDetails.fpv_baseline) : await addDaysToDate(initial_ct_material_ship_baseline, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                protocol_approved_baseline,
                                contract_complete_baseline,
                                initial_ct_material_ship_baseline,
                                fpv_baseline
                            }
                        });
                        break;
                    case "protocol_approved_baseline":
                        var contract_complete_baseline = this.props.submissionDetails.contract_complete_baseline_check &&
                            this.props.submissionDetails.contract_complete_baseline ?
                            dateStringformat(this.props.submissionDetails.contract_complete_baseline) : await addDaysToDate(e, 150),
                            initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                                this.props.submissionDetails.initial_ct_material_ship_baseline ?
                                dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                                await addDaysToDate(contract_complete_baseline,
                                    type_of_material_provided === "Comm pack with aux sticker" ? 120 :
                                        type_of_material_provided === "Investigational label" ? 270 : 0),
                            fpv_baseline = this.props.submissionDetails.fpv_baseline_check &&
                                this.props.submissionDetails.fpv_baseline ?
                                dateStringformat(this.props.submissionDetails.fpv_baseline) :
                                await addDaysToDate(initial_ct_material_ship_baseline, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                contract_complete_baseline,
                                initial_ct_material_ship_baseline,
                                fpv_baseline
                            }
                        });
                        break;
                    case "contract_complete_baseline":
                        var initial_ct_material_ship_baseline = this.props.submissionDetails.initial_ct_material_ship_baseline_check &&
                            this.props.submissionDetails.initial_ct_material_ship_baseline ?
                            dateStringformat(this.props.submissionDetails.initial_ct_material_ship_baseline) :
                            await addDaysToDate(e, type_of_material_provided === "Comm pack with aux sticker" ? 120 :
                                type_of_material_provided === "Investigational label" ? 270 : 0),
                            fpv_baseline = this.props.submissionDetails.fpv_baseline_check &&
                                this.props.submissionDetails.fpv_baseline ?
                                dateStringformat(this.props.submissionDetails.fpv_baseline) :
                                await addDaysToDate(initial_ct_material_ship_baseline, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                initial_ct_material_ship_baseline,
                                fpv_baseline
                            }
                        });
                        break;
                    case "initial_ct_material_ship_baseline":
                        var fpv_baseline = this.props.submissionDetails.fpv_baseline_check &&
                            this.props.submissionDetails.fpv_baseline ?
                            dateStringformat(this.props.submissionDetails.fpv_baseline) : await addDaysToDate(e, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                fpv_baseline
                            }
                        });
                        break;
                    case "protocol_received_estimate":
                        var protocol_approved_estimate = await addDaysToDate(e, 30),
                            contract_complete_estimate = await addDaysToDate(protocol_approved_estimate, 90),
                            initial_ct_material_ship_estimate = await addDaysToDate(contract_complete_estimate, type_of_material_provided === "Comm pack with aux sticker" ? 120 :
                                type_of_material_provided === "Investigational label" ? 270 : 0),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                protocol_approved_estimate,
                                contract_complete_estimate,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "protocol_approved_estimate":
                        var contract_complete_estimate = await addDaysToDate(e, 90),
                            initial_ct_material_ship_estimate = await addDaysToDate(contract_complete_estimate, type_of_material_provided === "Comm pack with aux sticker" ? 120 :
                                type_of_material_provided === "Investigational label" ? 270 : 0),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                contract_complete_estimate,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "contract_complete_estimate":
                        var initial_ct_material_ship_estimate = await addDaysToDate(e, type_of_material_provided === "Comm pack with aux sticker" ? 120 :
                            type_of_material_provided === "Investigational label" ? 270 : 0),
                            fpv_estimate = await addDaysToDate(initial_ct_material_ship_estimate, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                initial_ct_material_ship_estimate,
                                fpv_estimate
                            }
                        });
                        break;
                    case "initial_ct_material_ship_estimate":
                        var fpv_estimate = await addDaysToDate(e, 30)
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e,
                                fpv_estimate
                            }
                        });
                        break;
                    default:
                        this.setState({
                            milestones: {
                                ...milestones,
                                [eo.name]: e
                            }
                        });
                        break;
                }
                return false;
            }

        }

    }


    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "blank" && field.show)
                return <Col sm={field.colSize} key={field.type}></Col>
            return (
                <Col sm={field.type === "checkbox" ? 1 : 5} key={field.label} className={field.display ? "" : 'noDisplay'}>
                    <InputField
                        {...field}
                        value={this.state.milestones[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e) => { this.onInputChange(e, field) }) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleUpdateMilestonesModalShowHide, showHideModal, handleUpdateMilestones, submissionDetails, submissionDetails: { proposal_approval_date } } = this.props;
        const { error, errorMsg, milestones } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                    <Modal.Header closeButton onClick={() => handleUpdateMilestonesModalShowHide(false)}>
                        <Modal.Title>
                            Update Milestones
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {error ?
                            <div className="error_milestones">
                                {
                                    errorMsg
                                }

                            </div> :
                            <>
                                <Row>
                                    <Col className="text-center mb-3">
                                        Proposal Approval :
                                        {proposal_approval_date ? dateStringformat(proposal_approval_date) : ""}
                                    </Col>
                                </Row>
                                <Row>
                                    {this.renderInputFields()}
                                </Row>
                            </>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => handleUpdateMilestonesModalShowHide(false)}>
                            Cancel
                        </Button>
                        {
                            !error &&
                            <Button variant="primary" onClick={() => handleUpdateMilestones(milestones, submissionDetails)}>
                                Save
                            </Button>
                        }

                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}

export default UpdateMilestones;